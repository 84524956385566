import { TQMSVehicleInterface, VehicleCategory, VehicleInfo } from "./Fleet";

export const IFleetRepositoryToken = Symbol();

export interface IFleetRepository {
  createVehicleCategory: (values: any) => Promise<any>;
  getAllVechiles: (page: number, input: string, filter:string) => Promise<any>;
  getAVehicle: (id: number) => Promise<any>;
  deleteVehicle: (id: number) => Promise<any>;
  getAllVehicleCategory: (page: number, input: string,filter : string) => Promise<any>;
  deleteVehicleCategory: (id: number) => Promise<any>;
  getVehicleCategory: (id: number) => Promise<any>;
  updateVehicleCategory: (id: number, values: VehicleCategory) => Promise<any>;
  getUnits: () => Promise<any>;
  createUnit: (values: any) => Promise<any>;
  createVehicleTypes: (values: any) => Promise<any>;
  createTrip: (values: any) => Promise<any>;
  getAllTrip: (page: number, input: string,filter:string) => Promise<any>;
  getATrip: (id: number) => Promise<any>;
  deleteTrip: (id: number) => Promise<any>;
  getAllVehicleTypes: (page: number, input: string, filter :string) => Promise<any>;
  updateTripStatus: (id: number, values: any) => Promise<any>;
  updateTrip: (id: number, values: any) => Promise<any>;
  getAllChangeRequests: (page: number, input: string,filter:string) => Promise<any>;
  changeVehicleRequestStatus: (id: number, values: any) => Promise<any>;
  checkVehicleExistance: (values: any) => Promise<any>;
  changeVehicleOwn: (id: number, values: any) => Promise<any>;
  changeVehicleOthers: (id: number, values: any) => Promise<any>;
  getVehicleType: (id: number) => Promise<any>;
  deleteVehicleType: (id: number) => Promise<any>;
  getAllVehicleOwnerList: () => Promise<any>;
  getTripCoordinatesById: (id: number) => Promise<any>;
  getAllVehiclePlateColors: () => Promise<any>;
  getVehiclePolicyTypes: () => Promise<any>;
  createVehicle: (values: any) => Promise<any>;
  updateVehicle: (id: number, values: VehicleCategory) => Promise<any>;
  getAvailableVehicleForBidding: (id: number) => Promise<any>;
  getNewBids: (page: number, input?: string,filter?:string) => Promise<any>;
  newBidRequest: (values: any) => Promise<any>;
  getRejectedBids: (page: number, input: string,filter?:string) => Promise<any>;
  getAcceptedBids: (page: number, input?: string,filter?:string) => Promise<any>;
  acknowledgeDigitalContract: (values: any) => Promise<any>;
  cancelBidRequest: (values: any) => Promise<any>;
  acknowledgeContract: (values: any) => Promise<any>;

  //payment
  addpayment: (values: any) => Promise<any>;
  transferOwnership: (values: any) => Promise<any>;
  cancelOrder: (values: any) => Promise<any>;

  getAllVehicles: () => Promise<any>;
  getVehicleCheckList: (page: number, input: string) => Promise<any>;
  getVehicleLogHistory: (page: number, input: string) => Promise<any>;

  //report
  getDeclinedVehicleReport: (page: number, input: string) => Promise<any>;
  getRejectedVehicleReport: (page: number, input: string) => Promise<any>;

  getEditRequestVehicleReport: (page: number, input: string) => Promise<any>;
  getAwaitingApprovalVehicleReport: (
    page: number,
    input: string
  ) => Promise<any>;

  getAwaitingApprovalDriverReport: (
    type: string,
    page: number,
    input: string
  ) => Promise<any>;
  getStatusDriverReport: (values: any) => Promise<any>;
  getStatusVehicleReport: (values: any) => Promise<any>;
  getInsuranceCompany: () => Promise<any>;
  checkUniqueVehicleNumber: (plateNumber: string) => Promise<any>;
  getUnassignedDriver: () => Promise<any>;
  assignDriver: (values: any) => Promise<VehicleInfo>;

  updateVehicleStatus: (id: number, values: any) => Promise<any>;
  getOrderBidDetails: (id: number) => Promise<any>;

  //for getting onboarding data
  getOnboardingData: (
    status: string,
    type: string,
    page: number,
    input: string
  ) => Promise<any>;
  getVehicleOnBoardingLists: (page: number, input: string,filter:string) => Promise<any>;

  setVehicleEditOnBoarding: (id: number, value: any) => Promise<any>;

  getVehicleDetailsById: (value: { vehicleId: number }) => Promise<any>;

  getSthaniya: () => Promise<any>;
  getProformaInvoice: (id: number) => Promise<any>;
  getTQMSVehicleDetails: (id: number) => Promise<TQMSVehicleInterface>;
}
