import React, { lazy } from "react";
import { PublicRoute } from "../../../core/presentation/navigation/types/route";

const notificationRoutes = [
  {
    path: "/customized-notifications-shipper",
    Component: lazy(
      () => import("../pages/CustomizeNotification/ShipperNotification")
    ),
    permission: ["*"],
  },
  {
    path: "/customized-notifications-driver",
    Component: lazy(
      () => import("../pages/CustomizeNotification/DriverNotification")
    ),
    permission: ["*"],
  },
  {
    path: "/customized-notifications-vehicle-owner",
    Component: lazy(
      () => import("../pages/CustomizeNotification/VehicleOwnerNotification")
    ),
    permission: ["*"],
  },

  {
    path: "*",
    Component: lazy(() => import("../../../core/presentation/pages/NotFound")),
    permission: ["*"],
  },
];

export default notificationRoutes;
