import React from "react";
import { Modal } from "antd";
import { SuccessTickIcon } from "../../../assets/icons/SuccessTick";
import AButton from "./Button";
import { typography } from "theme/Typography";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  successMessage: string;
  additionalText?: string;
  continueButtonText: string;
  extraButton?: {
    text: string;
    onClick: () => void;
  };
}

const SuccessModal: React.FC<ModalProps> = ({
  show,
  onClose,
  successMessage,
  additionalText,
  continueButtonText,
  extraButton,
}) => {
  return (
    <Modal
      open={show}
      onCancel={onClose}
      width={400}
      closeIcon={null}
      centered
      footer={[
        extraButton && (
          <div
            key="downloadText"
            style={{ textAlign: "center", marginBottom: "10px" }}
            onClick={extraButton.onClick}
          >
            <p
              style={{
                margin: 0,
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {extraButton.text}
            </p>
          </div>
        ),
        <AButton
          key="continueButton"
          onClick={onClose}
          type="primary"
          fullwidth
        >
          {continueButtonText}
        </AButton>,
        // extraButton && (
        //   <AButton
        //     key="extraButton"
        //     onClick={extraButton.onClick}
        //     type="primary"
        //   >
        //     {extraButton.text}
        //   </AButton>
        // ),
      ]}
    >
      <div className="modal-body">
        <SuccessTickIcon />
        <p
          style={{
            fontSize: typography.fontSizes.lg,
            fontWeight: typography.fontWeights.semibold,
            lineHeight: "28px",
          }}
        >
          {successMessage}
        </p>

        {additionalText && (
          <p
            style={{
              fontSize: typography.fontSizes.sm,
              fontWeight: typography.fontWeights.normal,
              lineHeight: "20px",
            }}
          >
            {additionalText}
          </p>
        )}
      </div>
    </Modal>
  );
};

export default SuccessModal;
