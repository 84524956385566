import { Button, ButtonProps } from "antd";
import { color } from "../../../theme/Color";
import { typography } from "theme/Typography";

interface AButtonProps extends ButtonProps {
  fullwidth?: boolean;
  mode?:
    | "success"
    | "danger"
    | "primaryoutlined"
    | "dangeroutlined"
    | "darkoutlined"
    | "default";
  fontSize?: string | number;
  fontWeight?: number;
  padding?: string;
  marginTop?: string;
  height?: number | string;
}

function AButton({
  fullwidth = false,
  mode = "default",
  fontSize = "14px",
  height,
  marginTop,
  fontWeight = typography.fontWeights.semibold,
  padding,
  children,
  ...others
}: AButtonProps) {
  const style: React.CSSProperties = {
    width: fullwidth ? "100%" : "auto",
    marginTop: marginTop ? marginTop : "10px",
    padding: padding ? padding : "0 50px",
    color:
      mode === "success" || mode === "danger"
        ? "white"
        : mode === "primaryoutlined"
          ? color.PRIMARY
          : "default",
    backgroundColor:
      mode === "success"
        ? color.SUCCESS
        : mode === "danger"
          ? color.DANGER
          : "default",
    borderColor:
      mode === "primaryoutlined"
        ? color.PRIMARY
        : mode === "dangeroutlined"
          ? color.DANGER
          : mode === "darkoutlined"
            ? color.DARK
            : "default",

    fontSize: fontSize,
    fontWeight: fontWeight,
    height: height,
  };

  return (
    <Button style={style} {...others}>
      {children}
    </Button>
  );
}

export default AButton;
