// import User from "../business/Order";
import { IFleetRepository } from "../business/IFleetRepository";
import ApiService from "../../core/presentation/services/ApiService";
import { injectable } from "inversify-sugar";
import {
  TQMSVehicleInterface,
  VehicleCategory,
  VehicleInfo,
} from "fleet/business/Fleet";
import { filter } from "lodash";

@injectable()
class FleetRepository implements IFleetRepository {
  public async getAllVechiles(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/vehicle?page=${page}&limit=10${input ? `&search=${input}` : ""}${
              filter ? `&${filter}` : ""
            }`
          : `/vehicle${filter ? `?${filter}` : ""}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAVehicle(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(`/vehicle/${id}`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async deleteVehicle(id: number): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(`/vehicle/${id}`, "");
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async createVehicleCategory(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "/vehicle/vehicle-categories",
        values
      );
      return response.data;
    } catch (error) {
      //   return error;
      Promise.reject(error);
    }
  }

  public async transferOwnership(values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        "/vehicle/transfer-ownership",
        values
      );
      return response.data;
    } catch (error) {
      //   return error;
      Promise.reject(error);
    }
  }

  public async getAllVehicleCategory(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/vehicle/vehicle-categories?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `/vehicle/vehicle-categories${filter ? `?${filter}` : ""}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async deleteVehicleCategory(id: any): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(
        `/vehicle/vehicle-category/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getVehicleCategory(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `/vehicle/vehicle-category/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updateVehicleCategory(
    id: any,
    value: VehicleCategory
  ): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `/vehicle/vehicle-category/${id}`,
        value
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async changeVehicleOwn(id: any, value: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `/trip/vehicle-shift/${id}`,
        value
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async changeVehicleRequestStatus(id: any, value: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `trip/vehicle-change-request/${id}`,
        value
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async checkVehicleExistance(value: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost("/vehicle/filter", value);
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllChangeRequests(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/trip/vehicle-change-request?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `trip/vehicle-change-request${filter ? `?${filter}` : ""}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async changeVehicleOthers(id: any, value: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `/trip/vehicle-change/${id}`,
        value
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getUnits(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("/vehicle/units", "");
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async createUnit(value: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost("/vehicle/unit", value);
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async createVehicleTypes(value: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "/vehicle/vehicle-types",
        value
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllVehicleTypes(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/vehicle/vehicle-types?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `/vehicle/vehicle-types${filter ? `?${filter}` : ""}`,
        ""
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async deleteVehicleType(id: number): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(
        `/vehicle/vehicle-type/${id}`,
        ""
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getVehicleType(id: number): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `/vehicle/vehicle-type/${id}`,
        ""
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public getAllVehicleOwnerList = async () => {
    try {
      const response: any = await ApiService.getPost(
        "device/vehicle-owner",
        ""
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  public getTripCoordinatesById = async (id: number): Promise<any> => {
    try {
      const response: any = await ApiService.getPost("trip/coords/" + id, "");
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  public getAllVehiclePlateColors = async (): Promise<any> => {
    try {
      const response: any = await ApiService.getPost(
        "vehicle/plate-colors",
        ""
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  public getVehiclePolicyTypes = async (): Promise<any> => {
    try {
      const response: any = await ApiService.getPost(
        "vehicle/policy-types",
        ""
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  public createVehicle = async (values: any): Promise<any> => {
    try {
      const response: any = await ApiService.setPost("vehicle", values);
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  public async updateVehicle(id: any, value: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `/vehicle/${id}`,
        value
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async createTrip(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost("/trip", values);
      return response.data;
    } catch (error) {
      //   return error;
      Promise.reject(error);
    }
  }

  public async getAllTrip(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/trip?page=${page}&limit=10${input ? `&search=${input}` : ""}${
              filter ? `&${filter}` : ""
            }`
          : `/trip${filter ? `?${filter}` : ""}`,
        ""
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async deleteTrip(id: any): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(`/trip/${id}`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getATrip(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(`/trip/${id}`, "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async updateTripStatus(id: any, value: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(`/trip/status/${id}`, {
        tripStatus: value,
      });
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updateTrip(id: any, value: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(`/trip/${id}`, value);
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAvailableVehicleForBidding(id: number): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "order/bid-vehicles/" + id,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getNewBids(
    page: number,
    input?: string,
    filter?: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? //   ? `order/bid-request/new?page=${page}&limit=10&search=${input}`
            //   : "order/bid-request/new",
            `/order/bid-request/new?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `order/bid-request/new${filter ? `?${filter}` : ""}`,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async newBidRequest(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "order/bid-request",
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getRejectedBids(
    page: number,
    input: string,
    filter?: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? // `order/bid-request/rejected?page=${page}&limit=10&search=${input}`,
            `/order/bid-request/rejected?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `order/bid-request/rejected${filter ? `?${filter}` : ""}`,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAcceptedBids(
    page: number,
    input?: string,
    filter?: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/order/bid-request/accepted?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `order/bid-request/accepted${filter ? `?${filter}` : ""}`,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async acknowledgeDigitalContract(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "order/digital-contract-acknowledgement",
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async acknowledgeContract(id: number): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `order/contract-acknowledge/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async cancelBidRequest(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "order/bid-request/cancel",
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async cancelOrder(values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        "order/paid-bid/cancel",
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  //payment
  public async addpayment(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost("payment", values);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAllVehicles(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "vehicle/vehicle-locations",
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getVehicleCheckList(page: number, input: string): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/vehicle/vehicle-checklist-report?page=${page}&limit=10&search=${input}`
          : "/vehicle/vehicle-checklist-report",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getVehicleLogHistory(page: number, input: string): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `/vehicle/vehicle-log-history?page=${page}&limit=10&search=${input}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getDeclinedVehicleReport(
    page: number,
    input: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/vehicle/tqms?status=declined&status=rejected&type=vehicle&page=${page}&limit=10&search=${input}&searchFrom=report`
          : "/vehicle/tqms?status=declined&status=rejected&type=vehicle",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getRejectedVehicleReport(
    page: number,
    input: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/vehicle/tqms?status=rejected&type=vehicle&page=${page}&limit=10&search=${input}&searchFrom=report`
          : "/vehicle/tqms?status=rejected&type=vehicle",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getEditRequestVehicleReport(
    page: number,
    input: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/vehicle/tqms?status=edit-request&type=vehicle&page=${page}&limit=10&search=${input}&searchFrom=report`
          : "/vehicle/tqms?status=edit_request&type=vehicle",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async getAwaitingApprovalVehicleReport(
    page: number,
    input: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/vehicle/tqms?status=pending&type=vehicle&page=${page}&limit=10&search=${input}&searchFrom=report`
          : "/vehicle/tqms?status=pending&type=vehicle",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAwaitingApprovalDriverReport(
    type: string,
    page: number,
    input: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/users/tqms-user?type=${type}&status=pending&page=${page}&limit=10&search=${input}`
          : "/users/tqms-user",
        ""
      );

      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async getStatusDriverReport(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "/users/tqms-user",
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async getStatusVehicleReport(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        `/vehicle/tqms-fleet`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  //insurance company
  public async getInsuranceCompany(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "vehicle/insurance-company",
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async checkUniqueVehicleNumber(vehicleNumber: string): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "vehicle/unique-vehicle-number",
        {
          vehicleNumber,
        }
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getUnassignedDriver(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "vehicle/unassign-driver",
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async assignDriver(values: any): Promise<VehicleInfo> {
    try {
      const response: any = await ApiService.setPost(
        "vehicle/assign-driver",
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateVehicleStatus(id: number, values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        "vehicle/vehicle-status/" + id,
        values
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOrderBidDetails(id: number): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "order/bid-request/" + id,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  //for getting onboarding data of vehicle, driver and vehicle owner
  public async getOnboardingData(
    status: string,
    type: string,
    page: number,
    input: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `/users/tqms-user?status=${status}&type=${type}&page=${page}&limit=10&search=${input}`,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  //For getting the
  public async getVehicleOnBoardingLists(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        // `vehicle/tqms/vehicle-lists?page=${page}&limit=10&search=${input}`,

        // `vehicle/tqms/vehicle-lists`,
        page > 0
          ? `vehicle/tqms/vehicle-lists?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `vehicle/tqms/vehicle-lists${filter ? `?${filter}` : ""}`,

        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async setVehicleEditOnBoarding(
    id: number,
    value: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `vehicle/tqms-fleet/edit/${id}`,
        value
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getVehicleDetailsById(value: {
    vehicleId: number;
  }): Promise<any> {
    try {
      const response: any = await ApiService.setPost(`vehicle/filter`, value);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  //sthaniyay
  public async getSthaniya(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("vehicle/sthaniya", "");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getProformaInvoice(id: number): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `payment/proforma-invoice/${id}`,
        ""
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getTQMSVehicleDetails(
    id: number
  ): Promise<TQMSVehicleInterface> {
    try {
      const response: any = await ApiService.getPost(
        `vehicle/tqms-vehicle/${id}`,
        ""
      );
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default FleetRepository;
