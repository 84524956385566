import React, { useState } from "react";
import { Typography } from "antd";
import "../../styles/Terms&Conditions.css";
import { useNavigate } from "react-router-dom";
import { userThunks } from "../../store/userSlice";
import AButton from "core/presentation/components/Button";
import { SuccessTickIcon } from "../../../../assets/icons/SuccessTick";
import ATitle from "core/presentation/components/Title";
import FailedModal from "core/presentation/components/FailedModal";
import locales from "core/presentation/i18n/locales";
import { styles } from "./style";
import { jwtDecode } from "jwt-decode";
import { JwtTokens } from "user/business/User";
import { getRoleCode } from "core/presentation/utils/Helper";
import { AuthStorage } from "core/presentation/services/AuthTokenService";
import SuccessModal from "core/presentation/components/SuccessModal";
import Welcome from "../Welcome";
const { Text } = Typography;

const TermsandConditions: React.FC<{
  onClose: () => void;
  contact?: number;
  onSuccess?: () => void;
}> = ({ onClose, contact, onSuccess }) => {
  const roleCode = getRoleCode();

  const navigate = useNavigate();
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const handleAgreeTerms = async () => {
    try {
      const response = await userThunks.acceptTermsAndCondition();
      if (response.data.success) {
        if (onSuccess) {
          onSuccess();
          navigate("/");
          return;
        }
        if (roleCode === "VEHICLE_OWNER") {
          onClose();
          setShowSuccessModal(true);
        } else {
          navigate("/setPassword");
        }
      } else {
        setShowFailedModal(true);
      }
    } catch (error) {
      setShowFailedModal(true);
      console.error("Issue on Accept Terms and Conditions", error);
    }
  };
  const handleRetry = () => {
    setShowFailedModal(true);
  };
  return (
    <>
      <SuccessTickIcon />
      <ATitle
        className="term-title"
        content={locales.en.auth.pages.TermsandConditions.TermsandConditions}
        style={styles.TermTitle}
      ></ATitle>
      <Text className="term-text">
        {locales.en.auth.pages.TermsandConditions.lastRevised}
      </Text>
      <br />
      <br />
      <div style={{ maxHeight: "450px", overflowY: "auto" }}>
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.welcomeMessage}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFirstLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFirstLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementSecondLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSecondLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementThirdLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementThirdLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFourthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFourthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementFifthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFifthLineDescriptionA
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementFifthLineDescriptionB
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {" "}
          {locales.en.auth.pages.TermsandConditions.agreementSixthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSixthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {" "}
          {locales.en.auth.pages.TermsandConditions.agreementSeventhLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementSeventhLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementEighthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementEighthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementNinthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementNinthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {locales.en.auth.pages.TermsandConditions.agreementTenthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementTenthLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {" "}
          {locales.en.auth.pages.TermsandConditions.agreementEleventhLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementEleventhLineDescription
          }
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {" "}
          {locales.en.auth.pages.TermsandConditions.agreementTwelfthLine}
        </Text>
        <br />
        <br />
        <Text className="term-text">
          {
            locales.en.auth.pages.TermsandConditions
              .agreementTwelfthLineDescription
          }
        </Text>
      </div>
      <br />

      <AButton value="large" onClick={handleRetry} style={styles.DeclineButton}>
        {locales.en.auth.pages.TermsandConditions.Decline}
      </AButton>
      <AButton
        type="primary"
        value="large"
        onClick={handleAgreeTerms}
        style={styles.AcceptButton}
      >
        {locales.en.auth.pages.TermsandConditions.Accept}
      </AButton>
      <FailedModal
        show={showFailedModal}
        onClose={() => setShowFailedModal(false)}
        failedMessage={locales.en.failedModalTexts.TermsandConditionsDeclined}
        additionalText={
          locales.en.failedModalTexts.TermsandConditionsDeclinedSub
        }
        continueButtonText={locales.en.failedModalTexts.TryAgain}
      />
      <SuccessModal
        show={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          setShowWelcomeModal(true);
        }}
        successMessage="Account Creation Successful"
        additionalText="Congratulations! Your account has been successfully created."
        continueButtonText={"Continue"}
      />
      <Welcome
        showWelcomeModal={showWelcomeModal}
        onClose={() => {
          setShowWelcomeModal(false);
          navigate("/additional-information-vehicle-owner");
        }}
      />
    </>
  );
};

export default TermsandConditions;
