export const getColor = (mode: string) => {
  return {
    PRIMARY: mode === "Dark" ? "#275e82" : "#275e82",
    SECONDARY: mode === "Dark" ? "#ff8347" : "#f75d2d",
    DANGER: mode === "Dark" ? "#ff4f5e" : "#dd283d",
    SUCCESS: mode === "Dark" ? "#42c87c" : "#1a9c46",
    WARNING: mode === "Dark" ? "#ffd74d" : "#ffb530",
    INFO: mode === "Dark" ? "#5cb8cc" : "#009aae",
    LIGHT: mode === "Dark" ? "#394145" : "#f7f8f9",
    DARK: mode === "Dark" ? "#ffffff" : "#2d3338",
    WHITE: mode === "Dark" ? "#000000" : "#ffffff",
    CONTENT: mode === "Dark" ? "#8c949b" : "#606a71",
    ERROR: mode === "Dark" ? "#FECDCA" : "#FEF3F2",
    ERRORLIGHT: mode === "Light" ? "#FEF3F2" : "#FECDCA",
  };
};

export const color = {
  PRIMARY: "#EA5A29",
  SECONDARY: "#f75d2d",
  DANGER: "#dd283d",
  BLACK: "#000000",
  SUCCESS: "#1a9c46",
  WARNING: "#ffb530",
  INFO: "#009aae",
  LIGHT: "#f7f8f9",
  DARK: "#333333",
  WHITE: "#ffffff",
  ERROR: "#B42318",
  CONTENT: "#606a71",
  TEXT: "#475467",
  SHADOWGREY1: "#1018280F",
  SHADOWGREY2: "#1018281A",
  INPUT_TEXT: "#344054",
  SUCCESS_TEXT: "52c41a",
  DANGER_TEXT: "f5222d",
  PRIMARY_TEXT: "#1890ff",
  TEXT_COLOR: "#101828",
  BACKGROUND_GREY1: "#ECEAEA",
  LIGHT_GRAY: "#7B8085",
  HR_GREY: "#D2D7DC",
  BORDER_GREY: "#E4E4E4",
  SHADOW_ORANGE: "#F26533",
  BUTTON_COLOR: "#1D2939",
  SIDEBAR_TEXT: "#344054",
  LIGHT_SUCCESS: "#ECFDF3",
  LIGHT_WARNING: "#FFFAEB",
  LIGHT_DANGER: "#ffa39e",
  GRAY: "#F6F6F6",
  GREEN: "#3BBE26",
};

export const opacity = {
  0: 0,
  5: 0.05,
  10: 0.1,
  20: 0.2,
  25: 0.25,
  30: 0.3,
  40: 0.4,
  50: 0.5,
  60: 0.6,
  70: 0.7,
  75: 0.75,
  80: 0.8,
  90: 0.9,
  95: 0.95,
  100: 1,
};
