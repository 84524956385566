// import { I18n as I18nJs, Scope, TranslateOptions } from "i18n-js";
// import locales from "./locales";
// import { injectable } from "inversify-sugar";

// @injectable()
// class I18n {
//   private readonly i18nJs;

//   constructor() {
//     this.i18nJs = new I18nJs(locales);

//     this.i18nJs.enableFallback = true;
//     this.i18nJs.locale = navigator.language;
//     this.i18nJs.locale = "es";
//   }

//   public t(scope: Scope, options?: TranslateOptions) {
//     return this.i18nJs.t(scope, options);
//   }
// }

// export default I18n;

import { I18n as I18nJs, Scope, TranslateOptions } from "i18n-js";
import locales from "./locales";
import { injectable } from "inversify-sugar";

@injectable()
class I18n {
  private readonly i18nJs;

  constructor() {
    this.i18nJs = new I18nJs(locales);

    this.i18nJs.enableFallback = true;
    this.i18nJs.locale = "en";
  }

  public t(scope: Scope, options?: TranslateOptions) {
    return this.i18nJs.t(scope, options);
  }
}

export default I18n;
