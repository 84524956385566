import axios from "axios";
import { toast } from "react-toastify";

import { AuthStorage } from "./AuthTokenService";
import { jwtDecode } from "jwt-decode";

const getBaseUrl = async () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  return API_BASE_URL;
};

const service = axios.create({
  headers: {
    Accept: "application/json",
    // 'Content-Type': 'multipart/form-data'
  },
});

export const blobService = axios.create({
  headers: {
    Accept: "application/json",
  },
  responseType: "blob",
});

// Config
const TOKEN_PAYLOAD_KEY = "authorization";
// const PUBLIC_REQUEST_KEY = 'public-request'

const refreshAccessTokenAfterExpiration = async () => {
  const accessToken = localStorage.getItem("accessToken") || "";
  if (accessToken) {
    if (AuthStorage.isTokenExpired(accessToken)) {
      try {
        await refreshAccessToken();
      } catch (refresherror) {
        console.log("refresh token failed", refresherror);
      }
    }
  }
};

// API Request interceptor
service.interceptors.request.use(
  async (config) => {
    await refreshAccessTokenAfterExpiration();
    config.baseURL = await getBaseUrl();
    const jwtToken = await localStorage.getItem("accessToken");
    // const jwtToken = await AuthStorage.getToken();
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    }

    return config;
  },
  (error) => {
    toast.error("Error...");
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error.response && error.response.status === 400) {
      // Check if the server provided a custom error message
      if (error.response.data && error.response.data.message) {
        toast.error("Error", error.response.data.message);
        return Promise.reject(error);
      }

      // If no custom error message is provided, you can handle it generically
      toast.error("Invalid data provided.");
      return Promise.reject(error);
    }

    // Handle other status codes or errors here
    if (error.response && error.response.status === 422) {
      return Promise.reject(error.response?.data?.errors);
    }

    if (error.response && error.response.status === 404) {
      toast.error("Not Found");
    }

    if (error.response && error.response.status === 500) {
      toast.error("Internal Server Error");
    }

    if (error.response && error.response.status === 508) {
      toast.error("Time Out");
    }

    toast.error("Error Occurred!!");

    return Promise.reject(error);
  }
);
export default service;
const refreshAccessToken = async () => {
  try {
    const response = await axios({
      url: (await getBaseUrl()) + "/auth/refresh",
      method: "get",
      headers: {
        Authorization: "Bearer " + (await AuthStorage.getFreshToken()),
        "Content-Type": "application/json",
      },
    });

    await AuthStorage.setSession(response.data.data);
    return response.data.data.accessToken;
  } catch (refreshError) {
    console.log("Refresh token failed", refreshError);
    throw refreshError;
  }
};
