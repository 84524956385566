import { configureStore } from "@reduxjs/toolkit";
import { fleetReducer } from "fleet/presentation/store/fleetSlice";
import { orderReducer } from "order/presentation/store/orderSlice";
import { userReducer } from "user/presentation/store/userSlice";
import { notificationReducer } from "notifications/presentation/store/notificationSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    fleet: fleetReducer,
    order: orderReducer,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
