export const typography = {
  letterSpacings: {
    xs: '-0.05em',
    sm: '-0.025em',
    md: 0,
    lg: '0.025em',
    xl: '0.05em',
    twoxl: '0.1em',
  },
  lineHeights: {
    twoxs: '1em',
    xs: '1.125em',
    sm: '1.25em',
    md: '1.375em',
    lg: '1.5em',
    xl: '1.75em',
    twoxl: '2em',
    threexl: '2.5em',
    fourxl: '3em',
    fivexl: '4em',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
    extraBlack: 950,
  },
  fonts: {
    heading: undefined,
    body: undefined,
    mono: undefined,
  },
  fontSizes: {
    twoxs: 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    twoxl: 24,
    threexl: 30,
    fourxl: 36,
    fivexl: 48,
    sixxl: 60,
    sevenxl: 72,
    eightxl: 96,
    ninexl: 128,
  },
};
