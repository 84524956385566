import { getModuleContainer, module } from "inversify-sugar";
import FleetRepository from "./infrastructure/FleetRepository";
import FleetUseCase from "./application/FleetUseCase";
import { IFleetRepositoryToken } from "./business/IFleetRepository";

@module({
  providers: [
    {
      provide: IFleetRepositoryToken,
      useClass: FleetRepository,
    },
    FleetUseCase,
  ],
})
export class FleetModule {}

export const fleetModuleContainer = getModuleContainer(FleetModule);
