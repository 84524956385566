import { injectable, provided } from "inversify-sugar";
import * as IOrderRepository from "../business/IOrderRepository";
@injectable()
export default class OrderUseCase {
  @provided(IOrderRepository.IOrderRepositoryToken)
  private orderRepository!: IOrderRepository.IOrderRepository;

  public getUnits() {
    return this.orderRepository.getUnits();
  }

  public getVehicleTypes() {
    return this.orderRepository.getVehicleTypes();
  }

  public getAllPickupLocations() {
    return this.orderRepository.getAllPickupLocations();
  }

  public getAllOrders(status?: string, page?: number, input?: string, filter?:string) {
    return this.orderRepository.getAllOrders(status, page, input, filter);
  }

  public getBidHistory() {
    return this.orderRepository.getBidHistory();
  }

  public getPaidBidCancel(page: number, input: string,filter:string ) {
    return this.orderRepository.getPaidBidCancel(page, input,filter);
  }

  public rebid(values: any) {
    return this.orderRepository.rebid(values);
  }

  public getAllDeliveryLocations() {
    return this.orderRepository.getAllDeliveryLocations();
  }

  public getOrderDetail(id: any, deletePrev? : boolean) {
    return this.orderRepository.getOrderDetail(id,deletePrev);
  }

  public getBidRequests(id: any) {
    return this.orderRepository.getBidRequests(id);
  }

  public updateOrder(id: any, values: any) {
    return this.orderRepository.updateOrder(id, values);
  }

  public orderCancelShipper(id: any, values: any) {
    return this.orderRepository.orderCancelShipper(id, values);
  }
  public placeOrder(values: any,action?:string) {
    return this.orderRepository.placeOrder(values, action);
  }

  public getShipperBids = () => {
    return this.orderRepository.getShipperBids();
  };

  public putSubmitOrder = (values : any, orderId: number) => {
    return this.orderRepository.putSubmitOrder(values,orderId);
  };

  public shipperAcceptBids = (bidId: number) => {
    return this.orderRepository.shipperAcceptBids(bidId);
  };

  public getCoords = (values: any) => {
    return this.orderRepository.getCoords(values);
  };

  public getDigitalContractWithBidRequest(id: any) {
    return this.orderRepository.getDigitalContractWithBidRequest(id);
  }

  public acknowledgeDigitalContract(values: any) {
    return this.orderRepository.acknowledgeDigitalContract(values);
  }

  public updateDraftOrder(id: any, values: any) {
    return this.orderRepository.updateDraftOrder(id, values);
  }

  public cancelRequestShipper(orderNumber: any, values: any) {
    return this.orderRepository.cancelRequestShipper(orderNumber, values);
  }

  public getNewRequest(page?: number) {
    return this.orderRepository.getNewRequest(page);
  }

  public getAcceptedRequest(page?: number) {
    return this.orderRepository.getAcceptedRequest(page);
  }

  public getRequestAndOrder(status?: string, page?: number, input?: string, filter?: string) {
    return this.orderRepository.getRequestAndOrder(status, page, input, filter);
  }

  public getBidList(vehicleSpecId: number) {
    return this.orderRepository.getBidList(vehicleSpecId);
  }

  public cancelOrder = (orderId: number) => {
    console.log("here in oprder moduke container");
    return this.orderRepository.cancelOrder(orderId);
  };

  public extendExpiry = (id: number) => {
    return this.orderRepository.extendExpiry(id);
  };

}
