// ContentWidthContext.tsx
import React, { createContext, useContext, useState, useEffect } from "react";

const ContentWidthContext = createContext(0);

export const useContentWidth = () => useContext(ContentWidthContext);

export const ContentWidthProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [contentWidth, setContentWidth] = useState(0);

  useEffect(() => {
    const updateContentWidth = () => {
      const contentElement = document.getElementById("main-content");
      if (contentElement) {
        setContentWidth(contentElement.offsetWidth);
      }
    };

    updateContentWidth();
    window.addEventListener("resize", updateContentWidth);

    return () => {
      window.removeEventListener("resize", updateContentWidth);
    };
  }, []);

  return (
    <ContentWidthContext.Provider value={contentWidth}>
      {children}
    </ContentWidthContext.Provider>
  );
};
