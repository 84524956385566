import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const orderIcon = () => (
  <svg
    id="Layer_1_1_"
    enable-background="new 0 0 64 64"
    viewBox="0 0 64 64"
    width="25"
    fill="currentColor"
    height="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m52 26.713v-12.299l6-6v20.586h2v-23h-.009c0-.129-.016-.259-.067-.383-.154-.374-.52-.617-.924-.617h-48c-.266 0-.52.105-.707.293l-7.999 7.999c-.181.181-.294.431-.294.708v38c0 .553.447 1 1 1h48c.553 0 1-.447 1-1v-11.96c1.258 1.843 2 4.065 2 6.46 0 6.341-5.159 11.5-11.5 11.5-.553 0-1 .447-1 1s.447 1 1 1h2c9.649 0 17.5-7.851 17.5-17.5 0-6.963-4.098-12.972-10-15.787zm-1.414-13.713h-18.172l6-6h18.172zm-21.293.293c-.188.187-.293.441-.293.707v7h-4v-6.586l7.414-7.414h3.172zm-17.879-6.293h18.172l-6 6h-18.172zm-7.414 44v-36h19v7c0 .553.447 1 1 1h6c.553 0 1-.447 1-1v-7h19v10.899c-1.732-.576-3.577-.899-5.5-.899h-1.5v-5c0-.375-.209-.718-.542-.89-.335-.172-.735-.143-1.039.076l-14 10c-.263.188-.419.491-.419.814s.156.626.419.813l14 10c.304.218.704.245 1.039.076.333-.171.542-.514.542-.889v-4.989c2.668.115 5.103 1.143 7 2.779v13.21zm48.048 5.035c2.441-2.444 3.952-5.816 3.952-9.535 0-7.444-6.056-13.5-13.5-13.5h-.5c-.553 0-1 .447-1 1v4.057l-11.279-8.057 11.279-8.057v4.057c0 .553.447 1 1 1h2.5c8.547 0 15.5 6.953 15.5 15.5 0 5.809-3.211 10.881-7.952 13.535z" />
    <path d="m6 47h12v2h-12z" />
    <path d="m6 43h12v2h-12z" />
  </svg>
);

export const OrderIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={orderIcon} {...props} />
);
