import { injectable, provided } from "inversify-sugar";
import * as IUserRepository from "../business/IUserRepository";
import { VehicleTypeBasedInterface } from "user/business/User";

@injectable()
export default class UserUseCase {
  @provided(IUserRepository.IUserRepositoryToken)
  private userRepository!: IUserRepository.IUserRepository;

  public login(value: any) {
    return this.userRepository.login(value);
  }

  public reverseGeoCode(value: any) {
    return this.userRepository.reverseGeoCode(value);
  }

  public setPassword(value: any) {
    return this.userRepository.setPassword(value);
  }

  public setAuthUserPassword(value: any) {
    return this.userRepository.setAuthUserPassword(value);
  }

  public updateAuthMethod(value: any) {
    return this.userRepository.updateAuthMethod(value);
  }

  public updateIsFirstLogin(value: any) {
    return this.userRepository.setIsFirstLogin(value);
  }

  public acceptTerms() {
    return this.userRepository.acceptTerms();
  }
  public acceptTermsWithoutToken(values: any) {
    return this.userRepository.acceptTermsWithoutToken(values);
  }
  public signup(value: any) {
    return this.userRepository.signup(value);
  }

  public vehicleOwnerSignup(value: any) {
    return this.userRepository.vehicleOwnerSignup(value);
  }

  public createPlatformAdmin(value: any) {
    return this.userRepository.createPlatformAdmin(value);
  }

  public getAllPlatformAdmin(page: number, input: string,filter:string) {
    return this.userRepository.getAllPlatformAdmin(page, input,filter);
  }

  public getAPlatformAdmin(id: any) {
    return this.userRepository.getAPlatformAdmin(id);
  }

  public deletePlatformAdmin(id: any) {
    return this.userRepository.deletePlatformAdmin(id);
  }

  public updatePlatformAdmin(id: any, values: any) {
    return this.userRepository.updatePlatformAdmin(id, values);
  }

  public verifyEmail(value: any) {
    return this.userRepository.verifyEmail(value);
  }

  public verifyNumber(value: any) {
    return this.userRepository.verifyNumber(value);
  }

  public verifyUsername(value: any) {
    return this.userRepository.verifyUsername(value);
  }

  public resetPassword(value: any) {
    return this.userRepository.resetPassword(value);
  }

  public sendCode(value: any) {
    return this.userRepository.sendCode(value);
  }

  public createOtp(value: any) {
    return this.userRepository.createOtp(value);
  }

  public logout(id: any, values: any) {
    return this.userRepository.logout(id, values);
  }

  public loginWithPassword(value: any) {
    return this.userRepository.loginWithPassword(value);
  }

  public getRefreshToken() {
    return this.userRepository.getRefreshToken();
  }

  // User Profile
  public getAdministrativeDivision() {
    return this.userRepository.getAdministrativeDivision();
  }

  public updateUserProfile(values: any) {
    return this.userRepository.updateUserProfile(values);
  }

  public additonalInfoVehicleOwner(values: any, id: number) {
    return this.userRepository.additonalInfoVehicleOwner(values, id);
  }

  public updatePassword(value: any) {
    return this.userRepository.updatePassword(value);
  }

  public getUserProfile() {
    return this.userRepository.getUserProfile();
  }

  public getShipper() {
    return this.userRepository.getShipper();
  }

  //  Users
  public getAllVechileOwner() {
    return this.userRepository.getAllVechileOwner();
  }

  public getAllNewVechileOwner(page: number, input: string, filter:string) {
    return this.userRepository.getAllNewVechileOwner(page, input, filter);
  }

  public getNoOfVehicles() {
    return this.userRepository.getNoOfVehicles();
  }

  public createVechileOwner(values: any) {
    return this.userRepository.createVechileOwner(values);
  }

  public getAVechileOwner(id: any) {
    return this.userRepository.getAVechileOwner(id);
  }

  public deleteVechileOwner(id: any) {
    return this.userRepository.deleteVechileOwner(id);
  }

  public updateVechileOwner(id: any, values: any) {
    return this.userRepository.updateVechileOwner(id, values);
  }

  public createDriver(values: any) {
    return this.userRepository.createDriver(values);
  }

  public getAllDriver(page: number, input: string, filter :string) {
    return this.userRepository.getAllDriver(page, input,filter);
  }

  public getADriver(id: any) {
    return this.userRepository.getADriver(id);
  }

  public deleteDriver(id: any) {
    return this.userRepository.deleteDriver(id);
  }

  public updateDriver(id: any, values: any) {
    return this.userRepository.updateDriver(id, values);
  }

  public createShipper(values: any) {
    return this.userRepository.createShipper(values);
  }

  public getAllShipper(page: number, input: string, filter:string) {
    return this.userRepository.getAllShipper(page, input,filter);
  }

  public getAShipper(id: any) {
    return this.userRepository.getAShipper(id);
  }

  public deleteShipper(id: any) {
    return this.userRepository.deleteShipper(id);
  }

  public updateShipper(id: any, values: any) {
    return this.userRepository.updateShipper(id, values);
  }

  public getAllDriverDevices() {
    return this.userRepository.getAllDriverDevices();
  }
  public getAllDeviceLocation(tripId: any) {
    return this.userRepository.getAllDeviceLocation(tripId);
  }
  public getAllRolesList(page: number, input: string,filter :string) {
    return this.userRepository.getAllRolesList(page, input,filter);
  }
  public getAllPermissionsList() {
    return this.userRepository.getAllPermissionsList();
  }
  public getRolePermission(id: any) {
    return this.userRepository.getRolePermission(id);
  }

  public assignPermissions(values: any) {
    return this.userRepository.assignPermissions(values);
  }

  //hash key
  public getHashKey() {
    return this.userRepository.getHashKey();
  }
  public generateNewHash() {
    return this.userRepository.generateNewHash();
  }

  //digital contract key
  public getDigitalContract() {
    return this.userRepository.getDigitalContract();
  }
  public generateDigitalContract(values: any) {
    return this.userRepository.generateDigitalContract(values);
  }
  public assignDriver(values: any) {
    return this.userRepository.assignDriver(values);
  }

  public getDashboard(
    role: "ROLE_VEHICLE_OWNER" | "ROLE_PLATFORM" | "ROLE_SHIPPER" | "ROLE_OTHER"
  ) {
    return this.userRepository.getDashboard(role);
  }

  public getAllDistanceBasedFee() {
    return this.userRepository.getAllDistanceBasedFee();
  }

  public addDistanceBasedFee(values: any) {
    return this.userRepository.addDistanceBasedFee(values);
  }

  public editDistanceBasedFee(values: any) {
    return this.userRepository.editDistanceBasedFee(values);
  }

  public deleteAllDistanceBasedFee() {
    return this.userRepository.deleteAllDistanceBasedFee();
  }

  public switchDistanceBasedFee(values: any) {
    return this.userRepository.switchDistanceBasedFee(values);
  }

  public getPercentageBasedFee() {
    return this.userRepository.getPercentageBasedFee();
  }

  public addPercentageBasedFee(values: any) {
    return this.userRepository.addPercentageBasedFee(values);
  }
  public switchPercentageBasedFee(values: any) {
    return this.userRepository.switchPercentageBasedFee(values);
  }

  public getIncrementalBasedFee() {
    return this.userRepository.getIncrementalBasedFee();
  }

  public addIncrementalBasedFee(values: any) {
    return this.userRepository.addIncrementalBasedFee(values);
  }

  public switchIncrementalBasedFee(values: any) {
    return this.userRepository.switchIncrementalBasedFee(values);
  }

  public getVehicleTypeBasedRule() {
    return this.userRepository.getVehicleTypeBasedRule();
  }
  public updateVehicleTypeBasedRule(value: VehicleTypeBasedInterface) {
    return this.userRepository.updateVehicleTypeBasedRule(value);
  }

  public deleteVehicleTypeRule(id: number) {
    return this.userRepository.deleteVehicleTypeRule(id);
  }

  public getSpeedData() {
    return this.userRepository.getSpeedData();
  }

  public addMaxSpeed(values: any) {
    return this.userRepository.addMaxSpeed(values);
  }

  //post roles
  public addNewRoles(values: any) {
    return this.userRepository.addNewRoles(values);
  }

  //delete role
  public deleteRole(id: number) {
    return this.userRepository.deleteRole(id);
  }

  //shipment report
  public getShipmentTrackingReport(orderNumber: any) {
    return this.userRepository.getShipmentTrackingReport(orderNumber);
  }

  public getShipmentCalendarReport(date: string) {
    return this.userRepository.getShipmentCalendarReport(date);
  }

  public getShipmentDispatchReport() {
    return this.userRepository.getShipmentDispatchReport();
  }

  public getSkippedAdditionalInfo = () => {
    return this.userRepository.getSkippedAdditionalInfo();
  };
  public setEstimatedPriceRule(values: any) {
    return this.userRepository.setEstimatedPriceRule(values);
  }

  public getEstimatedPriceRule() {
    return this.userRepository.getEstimatedPriceRule();
  }

  public updateEstimatedPriceRule() {
    return this.userRepository.updateEstimatedPriceRule();
  }
}
