import React from "react";
import { color } from "theme/Color";

interface BgWrapperInterface {
  children?: React.ReactNode;
  HaveBackgroundColor: any;
}

const BgWrapper = ({ children, HaveBackgroundColor }: BgWrapperInterface) => {
  return (
    <div
      style={{
        width: "100%",
        // height: "100vh",
        backgroundColor: HaveBackgroundColor ? color.WHITE : "transparent",
        margin: "15px 10px 15px 0",
        boxShadow: HaveBackgroundColor
          ? `0px 2px 4px -2px ${color.SHADOWGREY1}, 0px 4px 8px -2px ${color.SHADOWGREY2}`
          : "",
        borderRadius: "8px",
        padding: "20px",
      }}
    >
      {children}
    </div>
  );
};

export default BgWrapper;
