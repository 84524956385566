import Navigation from "./core/presentation/navigation/index";
import { Provider } from "react-redux";
import store from "./core/presentation/store/index";
import ThemeConfigProvider from "./theme/ThemeConfigProvider";
import { useEffect } from "react";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Provider store={store}>
          <ThemeConfigProvider>
            <Navigation />
          </ThemeConfigProvider>
        </Provider>
      </header>
    </div>
  );
}

export default App;
