import { IOrderRepository } from "../business/IOrderRepository";
// import User from "../business/Order";
import ApiService from "../../core/presentation/services/ApiService";
import { injectable } from "inversify-sugar";
import { filter } from "lodash";

@injectable()
class OrderRepository implements IOrderRepository {
  public async getUnits(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("vehicle/units", "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getVehicleTypes(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("order/vehicle-types", "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async putSubmitOrder(values: any, orderId: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `order/identify-vehicle/${orderId}`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllPickupLocations(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "order/location/pickup",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllOrders(
    status?: string,
    page?: number,
    input?: string,
    filter?: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        status && page
          ? `order?status=${status}&page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : status
            ? `order?status=${status}&search=${input}${
                filter ? `&${filter}` : ""
              }`
            : page
              ? `order?page=${page}&limit=10${input ? `&search=${input}` : ""}${
                  filter ? `&${filter}` : ""
                }`
              : `order${filter ? `?${filter}` : ""}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getBidHistory(): Promise<any> {
    try {
      const response: any = await ApiService.getPost("order/bid-history", "");
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getPaidBidCancel(
    page: number,
    input: string,
    filter: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page > 0
          ? `/order/paid-bid/cancel?page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : `order/paid-bid/cancel${filter ? `?${filter}` : ""}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public rebid = async (values: any): Promise<any> => {
    try {
      const response: any = await ApiService.setPost("order/rebid", values);
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  public async getOrderDetail(id: any, deletePrev?: boolean): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        !!deletePrev ? `order/${id}?deletePrev=true` : `order/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async orderCancelShipper(id: any, values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `order/cancel/${id}`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getBidRequests(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `order/bid-request/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updateOrder(id: any, values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `order/specification/${id}`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAllDeliveryLocations(): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        "order/location/delivery",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async placeOrder(values: any, action?: string): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        action ? `order?action=${action}` : "order",
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public getShipperBids = async (): Promise<any> => {
    try {
      const response: any = await ApiService.getPost("order/bids", "");
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  public shipperAcceptBids = async (bidId: number): Promise<any> => {
    try {
      const response: any = await ApiService.getPost(
        `order/accept-bid/${bidId}`,
        ""
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  public getCoords = async (values: any): Promise<any> => {
    try {
      const response: any = await ApiService.setPost(`vehicle/coords`, values);
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  public async getDigitalContractWithBidRequest(id: any): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        `order/digital-contract/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async acknowledgeDigitalContract(values: any): Promise<any> {
    try {
      const response: any = await ApiService.setPost(
        "order/digital-contract-acknowledgement",
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async updateDraftOrder(id: number, values: any): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(`order/${id}`, values);
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async cancelRequestShipper(
    orderNumber: any,
    values: any
  ): Promise<any> {
    try {
      const response: any = await ApiService.deletePost(
        `order/${orderNumber}`,
        values
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getNewRequest(page?: number): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page ? `order/request/new?page=${page}&limit=10` : "order/request/new",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getAcceptedRequest(page?: number): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        page
          ? `order/request/accepted?page=${page}&limit=10`
          : "order/request/accepted",
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }

  public async getRequestAndOrder(
    status?: string,
    page?: number,
    input?: string,
    filter?: string
  ): Promise<any> {
    try {
      const response: any = await ApiService.getPost(
        status && page
          ? `order/requestandorder?status=${status}&page=${page}&limit=10${
              input ? `&search=${input}` : ""
            }${filter ? `&${filter}` : ""}`
          : status
            ? `order/requestandorder?status=${status}${
                filter ? `&${filter}` : ""
              }`
            : page
              ? `order/requestandorder?page=${page}&limit=10${
                  input ? `&search=${input}` : ""
                }${filter ? `&${filter}` : ""}`
              : `order/requestandorder${filter ? `?${filter}` : ""}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public getBidList = async (vehicleSpecId: number): Promise<any> => {
    try {
      const response: any = await ApiService.getPost(
        `order/vehicle-specification/${vehicleSpecId}`,
        ""
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  //delete order or cancel order
  public async cancelOrder(orderId: any): Promise<any> {
    console.log("Here", orderId);
    try {
      const response: any = await ApiService.deletePost(`order/${orderId}`, "");
      console.log("Here tresponse", response);

      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
  public async extendExpiry(id: number): Promise<any> {
    try {
      const response: any = await ApiService.updatePost(
        `order/extend-expiry-time/${id}`,
        ""
      );
      return response;
    } catch (error) {
      Promise.reject(error);
    }
  }
}
export default OrderRepository;
