import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const barsIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_3427_33067"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
      style={{ maskType: "alpha" }}
    >
      <rect width="24" height="24" fill="#667085" />
    </mask>
    <g mask="url(#mask0_3427_33067)">
      <path
        d="M2 21V19H22V21H2ZM3 18V11H6V18H3ZM8 18V6H11V18H8ZM13 18V9H16V18H13ZM18 18V3H21V18H18Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const BarsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={barsIcon} {...props} />
);
