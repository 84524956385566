import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { InversifySugar } from "inversify-sugar";
import AppModule from "AppModule";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

declare module "dayjs" {
  interface Dayjs {
    weekday: { (): number; (value: number): Dayjs };
    week: { (): number; (value: number): Dayjs };
    weekYear: () => number;
    localeData: () => InstanceLocaleDataReturn;
  }
}


dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

InversifySugar.options.debug = true;
InversifySugar.options.defaultScope = "Singleton";
InversifySugar.run(AppModule);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
