import { typography } from "theme/Typography";

export const styles = {
    TermTitle: {
        fontSize: typography.fontSizes.lg
    },

    TermTextDiv: {
        maxHeight: "450px", 
        overflowY: "auto"
    },

    DeclineButton: {
        width: "48%", 
        marginRight: "10px"
    },

    AcceptButton: {
        width: "48%"
    }
}