import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const platformAdminIcon = () => (
 <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.01667 11 6.1875 10.6625 5.5125 9.9875C4.8375 9.3125 4.5 8.48333 4.5 7.5C4.5 6.51667 4.8375 5.6875 5.5125 5.0125C6.1875 4.3375 7.01667 4 8 4C8.98333 4 9.8125 4.3375 10.4875 5.0125C11.1625 5.6875 11.5 6.51667 11.5 7.5C11.5 8.48333 11.1625 9.3125 10.4875 9.9875C9.8125 10.6625 8.98333 11 8 11ZM8 9C8.43333 9 8.79167 8.85833 9.075 8.575C9.35833 8.29167 9.5 7.93333 9.5 7.5C9.5 7.06667 9.35833 6.70833 9.075 6.425C8.79167 6.14167 8.43333 6 8 6C7.56667 6 7.20833 6.14167 6.925 6.425C6.64167 6.70833 6.5 7.06667 6.5 7.5C6.5 7.93333 6.64167 8.29167 6.925 8.575C7.20833 8.85833 7.56667 9 8 9ZM8 20C5.68333 19.4167 3.77083 18.0875 2.2625 16.0125C0.754167 13.9375 0 11.6333 0 9.1V3L8 0L16 3V9.1C16 11.6333 15.2458 13.9375 13.7375 16.0125C12.2292 18.0875 10.3167 19.4167 8 20ZM8 2.125L2 4.375V9.1C2 10 2.125 10.875 2.375 11.725C2.625 12.575 2.96667 13.375 3.4 14.125C4.1 13.775 4.83333 13.5 5.6 13.3C6.36667 13.1 7.16667 13 8 13C8.83333 13 9.63333 13.1 10.4 13.3C11.1667 13.5 11.9 13.775 12.6 14.125C13.0333 13.375 13.375 12.575 13.625 11.725C13.875 10.875 14 10 14 9.1V4.375L8 2.125ZM8 15C7.4 15 6.81667 15.0667 6.25 15.2C5.68333 15.3333 5.14167 15.5167 4.625 15.75C5.10833 16.25 5.63333 16.6833 6.2 17.05C6.76667 17.4167 7.36667 17.7 8 17.9C8.63333 17.7 9.23333 17.4167 9.8 17.05C10.3667 16.6833 10.8917 16.25 11.375 15.75C10.8583 15.5167 10.3167 15.3333 9.75 15.2C9.18333 15.0667 8.6 15 8 15Z" fill="currentColor"/>
</svg>

);

export const PlatformAdminIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={platformAdminIcon} {...props} />
);
