import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "core/presentation/store";
import { fleetModuleContainer } from "fleet/FleetModule";
import FleetUseCase from "../../application/FleetUseCase";
import { message } from "antd";
import { filter } from "lodash";

const initialState: any = {
  vehicleCategories: [],
  vehicleCategory: {},
  units: [],
  vehicle: {},
  vehicles: [],
  vehicleOwner: [],
  shiftChangeRequests: [],
  shiftChangeRequestsCount: 0,
  VehicleCategoryCount: 0,
  vehicleTypes: [],
  vehicleType: {},
  trips: [],
  vehiclePlateColors: [],
  declinedVehicleReport: [],
  rejectedVehicleReport: [],
  editRequestvehicleReport: [],
  awaitingApprovalVehicleReport: [],
  tripDetails: {},
  insuranceCompany: [],
  unAssignedDriver: [],
  vehicleTypeCount: 0,
  vehicleChecklistCount: 0,
  declinedVehicleReportCount: 0,
  rejectedVehicleReportCount: 0,
  editRequestvehicleReportCount: 0,
  awaitingApprovalDriverReportCount: 0,
  awaitingApprovalVehicleReportCount: 0,
  tqmsOnBoardingData: [],
  tqmsOnBoardingDataCount: 0,
  vehicleOnBoardingList: [],
  vehicleOnBoardingListCount: 0,
  sthaniya: [],
};

export const createVehicleCategoryThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("fleet/createVehicleCategory", async (data: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.createVehicleCategory(data);
  return response;
});

export const getAllVechilesThunk = createAsyncThunk<
  any,
  { page: number; input: string; filter: string },
  { state: RootState }
>("auth/vehicles", async ({ page, input, filter }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getAllVechiles(page, input, filter);
  return response;
});

export const getAVehicleThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/vehicle", async (id: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getAVehicle(id);
  return response.data;
});

export const deleteVehicleThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/deleteVehicle", async (id: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.deleteVehicle(id);
  return response;
});

export const getAllVehicleCategoryThunk = createAsyncThunk<
  any,
  { page: number; input: string; filter: string },
  { state: RootState }
>("vehicle/vehicleCategories", async ({ page, input, filter }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getAllVehicleCategory(page, input, filter);
  return response;
});

export const deleteVehicleCategoryThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/deleteVehicleCategory", async (id: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.deleteVehicleCategory(id);
  return response.data;
});

export const getVehicleCategoryThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/vehicleCategory", async (id: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getVehicleCategory(id);
  return response.data;
});

export const updateVehicleCategoryThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/updateVehicleCategory", async ({ id, values }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.updateVehicleCategory(id, values);
  return response;
});

export const changeVehicleRequestStatusThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/changeVehicleStatus", async ({ id, values }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.changeVehicleRequestStatus(id, values);
  return response;
});

export const updateVehicleThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/updateVehicle", async ({ id, values }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.updateVehicle(id, values);
  return response;
});

export const getUnitsThunk = createAsyncThunk<any, void, { state: RootState }>(
  "vehicle/getUnits",
  async () => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    const response = await useCase.getUnits();
    return response;
  }
);

export const createUnitThunk = createAsyncThunk<any, any, { state: RootState }>(
  "vehicle/createUnit",
  async (values) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    const response = useCase.createUnit(values);
    return response;
  }
);

export const createVehicleTypesThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/createVehicleType", async (values) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.createVehicleTypes(values);
  return response;
});

export const getAllVehicleTypesThunk = createAsyncThunk<
  any,
  { page: number; input: string; filter: string },
  { state: RootState }
>("vehicle/getAllVehicleTypes", async ({ page, input, filter }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.getAllVehicleTypes(page, input, filter);
  return response;
});

export const getVehicleTypeThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/getVehicleType", async (id: number) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.getVehicleType(id);
  return response;
});

export const getATripThunk = createAsyncThunk<any, any, { state: RootState }>(
  "vehicle/getATrip",
  async (id: number) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    const response = useCase.getATrip(id);
    return response;
  }
);

export const getAllVehicleOwnerList = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("vehicle/getVehicleOwners", async () => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.getAllVehicleOwnerList();
  return response;
});

export const deleteVehicleTypeThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/deleteVehicleType", async (id: number) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.deleteVehicleType(id);
  return response;
});

export const deleteTripThunk = createAsyncThunk<any, any, { state: RootState }>(
  "vehicle/deleteTrip",
  async (id: number) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    const response = useCase.deleteTrip(id);
    return response;
  }
);

export const updateTripThunk = createAsyncThunk<any, any, { state: RootState }>(
  "vehicle/updateTrip",
  async ({ id, values }) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    const response = useCase.updateTrip(id, values);
    return response;
  }
);

export const getAllChangeShiftRequestsThunk = createAsyncThunk<
  any,
  { page: number; input: string ,filter:string},
  { state: RootState }
>("vehicle/shiftChangeRequests", async ({ page, input ,filter}) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getAllChangeRequests(page, input,filter);
  return response;
});

export const changeVehicleOwnThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/changeVehicleOwn", async ({ id, values }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.changeVehicleOwn(id, values);
  return response;
});

export const changeVehicleOthersThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/changeVehicleOthers", async ({ id, values }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.changeVehicleOthers(id, values);
  return response;
});

export const getTripCoordinatesById = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/getTripCoordinates", async (id: number) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.getTripCoordinatesById(id);
  return response;
});

export const getAllVehiclePlateColorsThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("vehicle/getAllVehiclePlateColors", async () => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.getAllVehiclePlateColors();
  return response;
});

export const getVehiclePolicyTypesThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("vehicle/getVehiclePolicyTypes", async () => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.getVehiclePolicyTypes();
  return response;
});

export const createVehicleThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/createVehicle", async (values: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.createVehicle(values);
  return response;
});

export const createTripThunk = createAsyncThunk<any, any, { state: RootState }>(
  "vehicle/createTrip",
  async (values: any) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    const response = useCase.createTrip(values);
    return response;
  }
);

export const checkVehicleExistanceThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/checkVehicle", async (values: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.checkVehicleExistance(values);
  return response;
});

export const transferOwnershipThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/transfer-ownership", async (values: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.transferOwnership(values);
  return response;
});

export const updateTripStatusThunk = createAsyncThunk<
  any,
  { id: any; tripStatus: string },
  { state: RootState }
>("vehicle/updateTripStatus", async ({ id, tripStatus }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.updateTripStatus(id, tripStatus);
  return response;
});
export const getAllTripThunk = createAsyncThunk<any, any, { state: RootState }>(
  "vehicle/trips",
  async ({ page, input,filter }: { page: number; input: string; filter:string }) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    const response = useCase.getAllTrip(page, input, filter);
    return response;
  }
);

//get new bids information
export const getNewBidsThunk = createAsyncThunk<
  any,
  { page: number; input?: string,filter:string }
>("user/getNewBidsThunk", async ({ page, input,filter }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.getNewBids(page, input, filter);
});

export const getAvailableVehicleForBiddingThunk = createAsyncThunk<
  any,
  { id: number }
>("fleet/get-availabl-vehicle-for", async ({ id }: { id: number }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.getAvailableVehicleForBidding(id);
});

//place new bid
export const newBidRequestThunk = createAsyncThunk<any, { state: RootState }>(
  "fleet/newBidRequestThunk",
  async (values: any) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    return await useCase.newBidRequest(values);
  }
);

//get rejected bids information
export const getRejectedBidsThunk = createAsyncThunk<
  any,
  { page: number; input: string;filter?:string }
>("user/getRejectedBidsThunk", async ({ page, input,filter}) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.getRejectedBids(page, input, filter);
});

//get accepted bids information
export const getAcceptedBidsThunk = createAsyncThunk<
  any,
  { page: number; input?: string,filter?:string }
>("user/getAcceptedBidsThunk", async ({ page, input,filter }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.getAcceptedBids(page, input,filter);
});

export const acknowledgeDigitalContractThunk = createAsyncThunk<
  any,
  { state: RootState }
>("fleet/acknowledgeDigitalContractThunk", async (values: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.acknowledgeDigitalContract(values);
});

export const acknowledgeContractThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("fleet/acknowledgeContractThunk", async (id: number) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.acknowledgeContract(id);
});

//cancel bid
export const cancelBidRequesthunk = createAsyncThunk<any, { state: RootState }>(
  "fleet/cancelBidThunk",
  async (values: any) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    return await useCase.cancelBidRequest(values);
  }
);

//payment
export const createPaymentThunk = createAsyncThunk<any, { state: RootState }>(
  "fleet/createPayment",
  async (values: any) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    return await useCase.addpayment(values);
  }
);

export const cancelOrderhunk = createAsyncThunk<any, { state: RootState }>(
  "fleet/cancelOrderRequesthunk",
  async (values: any) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    return await useCase.cancelOrder(values);
  }
);

export const getAllVehiclesForLiveTracking = createAsyncThunk<any>(
  "fleet/getAllVehiclesForLiveTracking",
  async () => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    return await useCase.getAllVehicles();
  }
);

export const getVehicleCheckListThunk = createAsyncThunk<
  any,
  { page: number; input: string },
  { state: RootState }
>("fleet/getVehicleChecklist", async ({ page, input }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getVehicleCheckList(page, input);
  return response.data;
});

export const getVehicleLogHistoryThunk = createAsyncThunk<
  any,
  { page: number; input: string },
  { state: RootState }
>("fleet/getVehicleLogHistory", async ({ page, input }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getVehicleLogHistory(page, input);
  return response.data;
});

export const getDeclinedVehicleReport = createAsyncThunk<
  any,
  { page: number; input: string },
  { state: RootState }
>("fleet/getDeclinedVehicleReport", async ({ page, input }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getDeclinedVehicleReport(page, input);
  return response.data;
});

export const getRejectedVehicleReport = createAsyncThunk<
  any,
  { page: number; input: string },
  { state: RootState }
>("fleet/getRejectedVehicleReport", async ({ page, input }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getRejectedVehicleReport(page, input);
  return response.data;
});

export const getEditRequestVehicleReport = createAsyncThunk<
  any,
  { page: number; input: string },
  { state: RootState }
>("fleet/getEditRequestVehicleReport", async ({ page, input }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getEditRequestVehicleReport(page, input);
  return response.data;
});

export const getAwaitingApprovalVehicleReport = createAsyncThunk<
  any,
  { page: number; input: string },
  { state: RootState }
>("fleet/getAwaitingApprovalVehicleReport", async ({ page, input }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getAwaitingApprovalVehicleReport(page, input);
  return response.data;
});
export const getAwaitingApprovalDriverReportThunk = createAsyncThunk<
  any,
  { type: string; page: number; input: string },
  { state: RootState }
>("fleet/getAwaitingApprovalDriverReport", async ({ type, page, input }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getAwaitingApprovalDriverReport(
    type,
    page,
    input
  );
  return response.data;
});
export const getStatusDriverReportThunk = createAsyncThunk<
  any,
  { state: RootState }
>("fleet/getStatusDriverReport", async (values: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getStatusDriverReport(values);
  return response.data;
});

export const getStatusVehicleReportThunk = createAsyncThunk<
  any,
  { values: any; id: string },
  { state: RootState }
>("fleet/getStatusVehicleReport", async (values: any) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getStatusVehicleReport(values);
  return response.data;
});

export const getInsuranceCompanyThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("vehicle/getInsuranceCompany", async () => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.getInsuranceCompany();
  return response;
});

export const checkUniqueVehicleNumber = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("vehicle/checkUniqueVehicleNumber", async (vehicleNumber) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.checkUniqueVehicleNumber(vehicleNumber);
  return response;
});

export const getProformaInvoiceThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("payment/getProformaInvoice", async (id) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.getProformaInvoice(id);
  return response;
});

export const getUnassignedDriverThunk = createAsyncThunk<any>(
  "user/getUnassignedDriver",
  async () => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    return await useCase.getUnAssignedDriver();
  }
);
export const updateVehicleStatusThunk = createAsyncThunk<
  any,
  { id: number; values: FleetUseCase }
>(
  "fleet/vehicle-status-update",
  async ({ id, values }: { id: number; values: any }) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    return await useCase.updateVehicleStatus(id, values);
  }
);

export const assignDriverThunk = createAsyncThunk<any, { state: RootState }>(
  "fleet/assign-driver-to-vehicle",
  async (values: any) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    const data = await useCase.assignDriver(values);
    return data;
  }
);

export const getOrderBidDetails = createAsyncThunk<any, { id: number }>(
  "fleet/getOrderBidsDetails",
  async ({ id }: { id: number }) => {
    const useCase = fleetModuleContainer.getProvided(FleetUseCase);
    return await useCase.getOrderBidDetails(id);
  }
);

//for getting onboarding data of vehicle, driver and vehicle owner
export const getOnBoardingDataThunk = createAsyncThunk<
  any,
  {
    status: string;
    type: string;
    page: number;
    input: string;
  },
  { state: RootState }
>("fleet/onboarding", async ({ status, type, page, input }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.getOnboardingData(status, type, page, input);
});

export const getVehicleOnBoardingListsThunk = createAsyncThunk<
  any,
  { page: number; input: string ,filter:string}
>("fleet/getVehicleOnBoardingLists", async ({ page, input,filter }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.getVehicleOnBoardingLists(page, input,filter);
});

export const setVehicleEditOnBoardingThunk = createAsyncThunk<
  any,
  { id: number; value: any }
>("fleet/setVehicleEditOnBoarding", async ({ id, value }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.setVehicleEditOnBoarding(id, value);
});

export const getVehicleDetailsByIdThunk = createAsyncThunk<
  any,
  { vehicleId: number },
  { state: RootState }
>("fleet/getVehicleDetails", async (value) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  return await useCase.getVehicleDetailsById(value);
});

export const getSthaniyaThunk = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("vehicle/getSthaniyaThunk", async () => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = useCase.getSthaniya();
  return response;
});

export const getTQMSVehicleDetailsThunk = createAsyncThunk<
  any,
  { id: number },
  { state: RootState }
>("users/tqmsVehicle-details", async ({ id }) => {
  const useCase = fleetModuleContainer.getProvided(FleetUseCase);
  const response = await useCase.getTQMSVehicleDetails(id);
  return response;
});

export const messageReceivedFromSocketThunk = createAsyncThunk<
  any,
  { state: RootState }
>("socket/message-received-remote", async (values: any) => {
  return;
});

export const fleetSlice = createSlice({
  name: "fleet",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllVechilesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicles = action?.payload?.data?.data?.vehicles;
        state.vehicleCount = action?.payload?.data?.data?.count;
      })
      .addCase(getAllVechilesThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAllVechilesThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllChangeShiftRequestsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shiftChangeRequests = action.payload.data.data;
        state.shiftChangeRequestsCount = action.payload.data.data?.count;
      })
      .addCase(getAllTripThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.trips = action.payload.data?.trips;
        state.tripCount = action.payload.data?.count;
      })
      .addCase(getAllTripThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAllTripThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAVehicleThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicle = action.payload.data.data;
      })

      .addCase(transferOwnershipThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedData = action.payload.data;
        const index = state.vehicles.findIndex(
          (vehicle: any) => vehicle.vehicleId === updatedData.vehicleId
        );
        if (index !== -1) {
          state.vehicles[index].vehicleOwner = updatedData.vehicleOwner;
        }
      })
      .addCase(transferOwnershipThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getAVehicleThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(deleteVehicleThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const vehicleId = action.meta.arg;
        state.vehicles = state?.vehicles?.filter(
          (vehicle: any) => vehicle.vehicleId !== vehicleId
        );
      })
      .addCase(deleteVehicleThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getATripThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.tripDetails = action.payload.data.data;
      })
      .addCase(getATripThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getATripThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(deleteTripThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const tripId = action.meta.arg;
        state.trips = state?.trips?.filter(
          (trip: any) => trip.tripId !== tripId
        );
      })
      .addCase(deleteTripThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder
      .addCase(getAllVehicleCategoryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicleCategories = action.payload.data.data;
        state.VehicleCategoryCount = action.payload?.data?.data?.count;
      })
      .addCase(getAllVehicleCategoryThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(deleteVehicleCategoryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const vehicleCategoryId = action.meta.arg;
        if (action?.payload?.success) {
          state.vehicleCategories = action?.payload?.data;
        }
      })
      .addCase(deleteVehicleCategoryThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getVehicleCategoryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicleCategory = action.payload.data.data;
      })
      .addCase(getVehicleCategoryThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getUnitsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.units = action.payload.data;
      })
      .addCase(getUnitsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(createUnitThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.units = [...state.units, action.payload.data];
      })
      .addCase(createUnitThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getAllVehicleTypesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicleTypes = action.payload.data;
        state.vehicleTypeCount = action.payload.data.count;
      })
      .addCase(getAllVehicleTypesThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(deleteVehicleTypeThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const vehicleTypeId = action.meta.arg;
        if (action?.payload?.success) {
          state.vehicleTypes = action?.payload?.data;
        }
      })
      .addCase(deleteVehicleTypeThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getVehicleTypeThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicleType = action.payload.data.data;
      })
      .addCase(getVehicleTypeThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getAllVehicleOwnerList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehicleOwnerLists = action?.payload;
      })
      .addCase(getAllVehicleOwnerList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getAllVehiclePlateColorsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehiclePlateColors = action?.payload.data;
      })
      .addCase(getAllVehiclePlateColorsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getVehiclePolicyTypesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vehiclePolicyTypes = action?.payload.data;
      })
      .addCase(getVehiclePolicyTypesThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getInsuranceCompanyThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.insuranceCompany = action?.payload.data;
      })
      .addCase(getInsuranceCompanyThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      })
      .addCase(getSthaniyaThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.sthaniya = action?.payload.data;
      })
      .addCase(getSthaniyaThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Unknown error";
      });

    builder.addCase(getNewBidsThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.newBidsInformation = action?.payload?.data?.bidRequest;
      state.newBidsInformationCount = action?.payload?.data?.count;
    });
    builder.addCase(getNewBidsThunk.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
    });
    builder.addCase(newBidRequestThunk.fulfilled, (state, action) => {
      state.newBidsInformation = action?.payload?.data?.data?.bidRequest;
    });
    builder.addCase(newBidRequestThunk.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
    });

    builder.addCase(getAcceptedBidsThunk.fulfilled, (state, action) => {
      // state.acceptedBidsInformation = action?.payload?.data?.bidRequest;
      state.acceptedBidsInformation = action?.payload?.data;
      state.acceptedBidsInformationCount = action?.payload?.data?.count;
    });
    builder.addCase(getAcceptedBidsThunk.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
    });

    builder.addCase(getRejectedBidsThunk.fulfilled, (state, action) => {
      state.rejectedBidsInformation = action?.payload?.data?.bidRequest;
      state.rejectedBidsInformationCount = action?.payload?.data?.count;
    });
    builder.addCase(getRejectedBidsThunk.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
    });

    builder.addCase(
      getAllVehiclesForLiveTracking.fulfilled,
      (state, action) => {
        state.vehiclesForTracking = action.payload.data;
      }
    );
    builder.addCase(getAllVehiclesForLiveTracking.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
    });

    builder
      .addCase(getVehicleCheckListThunk.fulfilled, (state, action) => {
        state.vehicleChecklist = action?.payload?.data;
        state.vehicleChecklistCount = action?.payload?.data?.count;
      })
      .addCase(getVehicleCheckListThunk.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });

    builder
      .addCase(getVehicleLogHistoryThunk.fulfilled, (state, action) => {
        state.vehicleLogHistory = action?.payload?.data?.vehicleLogHistory;
        state.vehicleLogHistoryCount = action?.payload?.data?.count;
      })
      .addCase(getVehicleLogHistoryThunk.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });
    builder
      .addCase(getDeclinedVehicleReport.fulfilled, (state, action) => {
        state.declinedVehicleReport = action?.payload?.data?.vehicles;
        state.declinedVehicleReportCount = action?.payload?.data?.count;
      })
      .addCase(getDeclinedVehicleReport.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });
    builder
      .addCase(getRejectedVehicleReport.fulfilled, (state, action) => {
        state.rejectedVehicleReport = action?.payload?.data?.vehicles;
        state.rejectedVehicleReportCount = action?.payload?.data?.count;
      })
      .addCase(getRejectedVehicleReport.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });
    builder
      .addCase(getEditRequestVehicleReport.fulfilled, (state, action) => {
        state.editRequestvehicleReport = action?.payload?.data?.vehicles;
        state.editRequestvehicleReportCount = action?.payload?.data?.count;
      })
      .addCase(getEditRequestVehicleReport.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });
    builder
      .addCase(getAwaitingApprovalVehicleReport.fulfilled, (state, action) => {
        state.awaitingApprovalVehicleReport = action?.payload?.data?.vehicles;
        state.awaitingApprovalVehicleReportCount = action?.payload?.data?.count;
      })
      .addCase(getAwaitingApprovalVehicleReport.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });

    builder
      .addCase(
        getAwaitingApprovalDriverReportThunk.fulfilled,
        (state, action) => {
          state.awaitingApprovalDriverReport = action?.payload?.data;
          state.awaitingApprovalDriverReportCount =
            action?.payload?.data?.count;
        }
      )
      .addCase(
        getAwaitingApprovalDriverReportThunk.rejected,
        (state, action) => {
          console.error(action.error.message || "unknown error");
        }
      );
    builder
      .addCase(updateTripStatusThunk.fulfilled, (state, action) => {
        const resData = action?.payload?.data;
        state.tripDetails.tripStatus = resData?.tripStatus;
      })
      .addCase(updateTripStatusThunk.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });
    builder
      .addCase(
        getStatusDriverReportThunk.fulfilled,
        (state: any, action: any) => {
          if (action?.payload?.success) {
            const { tqmsId } = action.meta.arg;
            state.tqmsOnBoardingData = state?.tqmsOnBoardingData?.filter(
              (data: any) => {
                return Number(data.tqmsUserId) !== tqmsId;
              }
            );
          }
        }
      )
      .addCase(getStatusDriverReportThunk.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });

    builder
      .addCase(
        getStatusVehicleReportThunk.fulfilled,
        (state: any, action: any) => {
          if (action?.payload?.success) {
            const { tqmsId } = action.meta.arg;
            state.awaitingApprovalVehicleReport =
              state?.awaitingApprovalVehicleReport.filter((data: any) => {
                return Number(data.tqmsFleetId) !== tqmsId;
              });
          }
        }
      )
      .addCase(getStatusVehicleReportThunk.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });

    builder
      .addCase(getUnassignedDriverThunk.fulfilled, (state, action) => {
        state.unAssignedDriver = action.payload.data;
      })
      .addCase(getUnassignedDriverThunk.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });

    builder
      .addCase(assignDriverThunk.fulfilled, (state, action) => {
        if (!!action?.payload?.success) {
          const updatedVehicle = action.payload.data;
          const index = state.vehicles.findIndex(
            (vehicle: any) => vehicle?.vehicleId === updatedVehicle?.vehicleId
          );

          if (index !== -1) {
            state.vehicles[index] = updatedVehicle;
          }
        }
      })
      .addCase(assignDriverThunk.rejected, (state, action) => {
        console.error(action.error.message || "unknown error");
      });

    builder.addCase(updateVehicleStatusThunk.fulfilled, (state, action) => {
      const updatedVehicle = action.payload.data;

      const index = state.vehicles.findIndex(
        (vehicle: any) => vehicle.vehicleId === updatedVehicle.vehicleId
      );

      if (index !== -1) {
        state.vehicles[index].vehicleStatus = updatedVehicle?.vehicleStatus;
      }
    });
    builder.addCase(updateVehicleStatusThunk.rejected, (state, action) => {
      console.error(action.error.message || "unknown error");
    });

    builder
      .addCase(getOnBoardingDataThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOnBoardingDataThunk.fulfilled, (state, action) => {
        const responseData = action?.payload?.data?.tqmsUsers;
        const responseDataCount = action?.payload?.data?.count;
        state.tqmsOnBoardingData = responseData;
        state.tqmsOnBoardingDataCount = responseDataCount;
        state.isLoading = false;
      })
      .addCase(getOnBoardingDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        message.error(action?.error?.message || "unknown error");
        console.error(action.error.message || "unknown error");
      });

    builder
      .addCase(getVehicleOnBoardingListsThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVehicleOnBoardingListsThunk.fulfilled, (state, action) => {
        const responseData = action?.payload?.data;
        const responseDataCount = action?.payload?.data?.count;
        state.vehicleOnBoardingList = responseData;
        state.vehicleOnBoardingListCount = responseDataCount;
        state.isLoading = false;
      })
      .addCase(getVehicleOnBoardingListsThunk.rejected, (state, action) => {
        state.isLoading = false;
        message.error(action?.error?.message || "unknown error");
        console.error(action.error.message || "unknown error");
      });
    builder.addCase(
      messageReceivedFromSocketThunk.fulfilled,
      (state, action) => {
        const socketData: any = action?.meta?.arg;
        const tripDetails = state?.vehiclesForTracking;

        const tripToUpdate = tripDetails?.find(
          (trip: any) => trip?.driverUserId === socketData?.content?.userId
        );

        if (tripToUpdate) {
          const newCoordinates = socketData?.content?.location?.coordinates;

          tripToUpdate.latitude = newCoordinates[0];
          tripToUpdate.longitude = newCoordinates[1];

          tripToUpdate.deviceCreatedAt = socketData?.content?.updateAt;
        } else {
          console.log("Trip not found here.");
        }
      }
    );
    builder.addCase(
      messageReceivedFromSocketThunk.rejected,
      (state, action) => {
        console.error(action.error.message || "unknown error");
      }
    );
  },
});

export const userSelectors = {};

export const fleetActions = fleetSlice.actions;

export const fleetThunks = {
  createVehicleCategoryThunk,
  getAllVechilesThunk,
  getAllVehicleCategoryThunk,
  deleteVehicleCategoryThunk,
  getVehicleCategoryThunk,
  updateVehicleCategoryThunk,
  getUnitsThunk,
  createUnitThunk,
  createVehicleTypesThunk,
  getAllVehicleTypesThunk,
  getVehicleTypeThunk,
  deleteVehicleTypeThunk,
  getAllVehicleOwnerList,
  deleteVehicleThunk,
  getTripCoordinatesById,
  getAVehicleThunk,
  updateVehicleThunk,
  getAllVehiclePlateColorsThunk,
  getVehiclePolicyTypesThunk,
  checkVehicleExistanceThunk,
  createVehicleThunk,
  createTripThunk,
  getAllTripThunk,
  getATripThunk,
  deleteTripThunk,
  updateTripStatusThunk,
  updateTripThunk,
  getAvailableVehicleForBiddingThunk,
  getNewBidsThunk,
  newBidRequestThunk,
  getRejectedBidsThunk,
  getAcceptedBidsThunk,
  acknowledgeDigitalContractThunk,
  cancelBidRequesthunk,
  createPaymentThunk,
  transferOwnershipThunk,
  getAllChangeShiftRequestsThunk,
  changeVehicleOwnThunk,
  changeVehicleOthersThunk,
  cancelOrderhunk,
  changeVehicleRequestStatusThunk,
  getAllVehiclesForLiveTracking,
  getVehicleCheckListThunk,
  getVehicleLogHistoryThunk,
  getDeclinedVehicleReport,
  getEditRequestVehicleReport,
  getAwaitingApprovalVehicleReport,
  getAwaitingApprovalDriverReportThunk,
  getStatusDriverReportThunk,
  getStatusVehicleReportThunk,
  getInsuranceCompanyThunk,
  checkUniqueVehicleNumber,
  getUnassignedDriverThunk,
  assignDriverThunk,
  updateVehicleStatusThunk,
  getOrderBidDetails,
  acknowledgeContractThunk,
  getOnBoardingDataThunk,
  getVehicleOnBoardingListsThunk,
  setVehicleEditOnBoardingThunk,
  getVehicleDetailsByIdThunk,
  getSthaniyaThunk,
  getProformaInvoiceThunk,
  getRejectedVehicleReport,
  getTQMSVehicleDetailsThunk,
  messageReceivedFromSocketThunk,
};

export const fleetReducer = fleetSlice.reducer;
