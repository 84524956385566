import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const tripIcon = () => (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.41797 14V12H9.41797C9.16797 9.7 8.18464 7.79167 6.46797 6.275C4.7513 4.75833 2.73464 4 0.417969 4V2C2.56797 2 4.54297 2.56667 6.34297 3.7C8.14297 4.83333 9.5013 6.36667 10.418 8.3C11.0513 6.95 11.8846 5.75417 12.918 4.7125C13.9513 3.67083 15.1096 2.76667 16.393 2H12.418V0H19.418V7H17.418V3.7C15.868 4.65 14.5346 5.825 13.418 7.225C12.3013 8.625 11.6346 10.2167 11.418 12H13.418V14H7.41797Z"
      fill="currentColor"
    />
  </svg>
);

export const TripIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={tripIcon} {...props} />
);
