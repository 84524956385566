import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
import React from "react";
import { color } from "theme/Color";
import { typography } from "theme/Typography";

interface ATextProps extends TextProps {
  fontSize?: number | string;
  fontWeight?: number;
  lineHeight?: string | number;
  margin?: string | number;
  whiteSpace?: string;
  mode?:
    | "success"
    | "danger"
    | "primary"
    | "dark"
    | "black"
    | "gray"
    | "white"
    | "textColor"
    | "default";
}

const AText = ({
  children,
  content,
  fontSize,
  fontWeight,
  lineHeight,
  mode,
  margin,
  whiteSpace,
  ...others
}: ATextProps) => {
  const { Text } = Typography;

  const defaultStyle: React.CSSProperties = {
    fontSize: typography.fontSizes.md,
    fontWeight: typography.fontWeights.normal,
    color: color.TEXT,
  };

  const getColorFromMode = (mode: ATextProps["mode"]) => {
    switch (mode) {
      case "success":
        return color.SUCCESS_TEXT;
      case "danger":
        return color.DANGER_TEXT;
      case "primary":
        return color.PRIMARY_TEXT;
      case "dark":
        return color.DARK;
      case "black":
        return color.BLACK;
      case "gray":
        return color.LIGHT_GRAY;
      case "white":
        return color.WHITE;
      case "textColor":
        return color.TEXT_COLOR;
      default:
        return color.TEXT;
    }
  };

  const style: React.CSSProperties = {
    ...defaultStyle,
    fontSize: fontSize || "16px",
    fontWeight: fontWeight || typography.fontWeights.normal,
    lineHeight: lineHeight || "24px",
    color: mode ? getColorFromMode(mode) : color.TEXT,
    margin: margin,
    whiteSpace: whiteSpace,
  };

  return (
    <Text style={style} {...others}>
      {content}
      {children}
    </Text>
  );
};

export default AText;
