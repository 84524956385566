import { module } from "inversify-sugar";

import { UserModule } from "./user/UserModule";
import { OrderModule } from "order/OrderModule";
import { FleetModule } from "fleet/FleetModule";
import { NotificationModule } from "notifications/NotificationModule";
import { CoreModule } from "core/CoreModule";

@module({
  imports: [
    UserModule,
    OrderModule,
    FleetModule,
    NotificationModule,
    CoreModule,
  ],
})
export default class AppModule {}
