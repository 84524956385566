export default {
  core: {
    errors: {
      screenNotFound: "Pantalla no encontrada",
    },
    screens: {
      NotFound: {
        goHome: "Volver a la pantalla de inicio",
      },
    },
  },
  post: {
    screens: {
      Posts: {
        loading: "Cargando...",
      },
      Post: {
        loading: "Cargando...",
      },
    },
  },
  auth: {
    pages: {
      Login: {
        LoginText: "Iniciar sesión",
        LoginSubText: "Por favor, ingrese sus datos.",
        Username: "Nombre de usuario",
        EnterUsername: "Ingrese el nombre de usuario",
        UsernamenotregisteredPleaseverify:
          "Nombre de usuario no registrado. Por favor verifique",
        Continue: "Continuar",
        DontHaveAccount: "¿No tienes una cuenta?",
        RegisterNow: "Regístrate ahora",
        ForgotPassword: "¿Olvidaste tu contraseña?",
        Next: "Siguiente",
        PasswordReset: "Restablecer la contraseña",
      },
    },
  },
};
