import { useEffect, useState } from "react";

import { getCurrentUserDetails, getFormattedFullName } from "../utils/Helper";
import { useAppDispatch } from "../store/hooks";
import AText from "./Text";
import { UserIcon } from "../../../assets/icons/UserIcon";
import { LogoutIcon } from "../../../assets/icons/LogoutIcon";
import ATitle from "./Title";
import { userThunks } from "user/presentation/store/userSlice";
import { useNavigate } from "react-router-dom";
import { color } from "theme/Color";
import { typography } from "theme/Typography";

function Footer() {
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [id, setId] = useState<number>();
  const [roles, setRoles] = useState<any[]>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchFormattedFullName = async () => {
    try {
      const userDetails = (await getCurrentUserDetails(dispatch)).user;
      const name = getFormattedFullName(
        userDetails.firstName,
        userDetails.lastName
      );
      setId(userDetails.userId);

      setFullName(name);
      setEmail(userDetails.email);
      setRoles(userDetails?.roles);
    } catch (error) {
      console.log("Error fetching formatted full name:", error);
    }
  };
  const userData = {
    id: id,
    isLogin: false,
  };
  useEffect(() => {
    fetchFormattedFullName();
  }, []);

  const handleLogout = async () => {
    await dispatch(userThunks.userLogout(userData));
    navigate("/");
  };

  return (
    <>
      <div
        style={{
          borderTop: `1px solid ${color.HR_GREY}`,
          padding: "10px 10px",
          display: "flex",
          alignItems: "center",
          justifyContent:'space-between',
          marginLeft:'20px'
        }}
      >
        <div style={{display:'flex', flexDirection:'row'}}>
      
        <UserIcon />
        <div
          style={{
            margin: "5px 5px 5px 10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ATitle
            style={{
              fontSize: typography.fontSizes.sm,
              fontWeight: typography.fontWeights.semibold,
            }}
            content={fullName}
          />
          {roles?.map((role) => (
            <AText
              fontSize={typography.fontSizes.xs}
              fontWeight={typography.fontWeights.bold}
            >
              {role?.roleName}
            </AText>
          ))}
          <AText fontSize={14}>{email}</AText>
        </div>
        </div>
        <LogoutIcon onClick={handleLogout} />
      </div>
    </>
  );
}

export default Footer;
