import restrictedUserRoutes from "user/presentation/routes/restrictedUserRoutes";
import userroutes from "../../../user/presentation/routes/userRoutes";
import orderRoutes from "order/presentation/routes/OrderRoutes";
import notificationRoutes from "notifications/presentation/routes/notificationRoutes";
import fleetRoutes from "fleet/presentation/routes/fleetRoutes";

export const publicRoutes = [...userroutes];
export const restrictedRoutes = [
  ...restrictedUserRoutes,
  ...orderRoutes,
  ...notificationRoutes,
  ...fleetRoutes,
];
