import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const notificationPlusIcon = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 20C7.45 20 6.97917 19.8042 6.5875 19.4125C6.19583 19.0208 6 18.55 6 18H10C10 18.55 9.80417 19.0208 9.4125 19.4125C9.02083 19.8042 8.55 20 8 20ZM14 11V8H11V6H14V3H16V6H19V8H16V11H14ZM0 17V15H2V8C2 6.61667 2.41667 5.3875 3.25 4.3125C4.08333 3.2375 5.16667 2.53333 6.5 2.2V1.5C6.5 1.08333 6.64583 0.729167 6.9375 0.4375C7.22917 0.145833 7.58333 0 8 0C8.41667 0 8.77083 0.145833 9.0625 0.4375C9.35417 0.729167 9.5 1.08333 9.5 1.5V2.2C9.73333 2.26667 9.9625 2.3375 10.1875 2.4125C10.4125 2.4875 10.625 2.58333 10.825 2.7C10.575 2.93333 10.35 3.1875 10.15 3.4625C9.95 3.7375 9.775 4.03333 9.625 4.35C9.375 4.23333 9.1125 4.14583 8.8375 4.0875C8.5625 4.02917 8.28333 4 8 4C6.9 4 5.95833 4.39167 5.175 5.175C4.39167 5.95833 4 6.9 4 8V15H12V12.2C12.3 12.3833 12.6167 12.5333 12.95 12.65C13.2833 12.7667 13.6333 12.8583 14 12.925V15H16V17H0Z"
      fill="currentColor"
    />
  </svg>
);

export const NotificationPlusIcon = (
  props: Partial<CustomIconComponentProps>
) => <Icon component={notificationPlusIcon} {...props} />;
