import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const groupIcon = () => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.62626 7.983C8.76922 7.983 10.5064 6.24579 10.5064 4.10283C10.5064 1.95987 8.76922 0.222656 6.62626 0.222656C4.48331 0.222656 2.74609 1.95987 2.74609 4.10283C2.74609 6.24579 4.48331 7.983 6.62626 7.983Z"
      fill="currentColor"
    />
    <path
      d="M12.3144 10.4082C9.1618 10.4082 6.62891 12.968 6.62891 16.0937C6.62891 19.2194 9.18874 21.7793 12.3144 21.7793C15.4401 21.7793 18 19.2194 18 16.0937C18 12.968 15.4671 10.4082 12.3144 10.4082ZM8.24564 13.6686C8.43426 13.3453 8.67677 13.0219 8.97318 12.7525C10.0779 11.6477 11.6138 11.1627 13.2036 11.4321C13.8234 11.5399 14.4162 11.7824 14.9282 12.1327C15.5479 12.5369 16.0329 13.0758 16.3832 13.6686C16.491 13.8572 16.4371 14.1267 16.2216 14.2345L16.0329 14.3153C15.0898 14.7464 14.012 14.7464 13.0689 14.3423C12.8533 14.2345 12.5839 14.1806 12.3144 14.1806C12.045 14.1806 11.8025 14.2345 11.56 14.3423C10.6169 14.7464 9.53903 14.7464 8.59594 14.3153L8.40732 14.2345C8.2187 14.1267 8.13786 13.8572 8.24564 13.6686ZM11.6138 19.8661L11.56 20.3242C11.533 20.5667 11.2905 20.7284 11.0749 20.6745C10.2935 20.4589 9.53903 20.0547 8.94623 19.4619C8.05702 18.5727 7.54506 17.3871 7.54506 16.1207C7.54506 15.8512 7.81451 15.6626 8.05702 15.7434L8.83845 15.959C9.61987 16.1746 10.3205 16.6326 10.8055 17.2793L10.8324 17.3063C11.3983 18.0069 11.6947 18.923 11.6138 19.8661ZM12.3683 17.0368C11.8025 17.0638 11.3444 16.6057 11.3713 16.0398C11.3983 15.5548 11.7755 15.1776 12.2605 15.1506C12.8264 15.1237 13.2845 15.5818 13.2575 16.1476C13.2575 16.6326 12.8533 17.0099 12.3683 17.0368ZM15.6557 19.435C15.0629 20.0278 14.3354 20.4589 13.5539 20.6475C13.3114 20.7014 13.0959 20.5398 13.0689 20.2972L13.042 19.8392C12.9611 18.8961 13.2575 17.9799 13.8234 17.2524L13.8503 17.2254C14.3354 16.5788 15.0359 16.1207 15.8174 15.9051L16.5718 15.6895C16.8144 15.6357 17.0569 15.7973 17.0569 16.0668C17.0569 17.3602 16.5718 18.5458 15.6557 19.435Z"
      fill="currentColor"
    />
    <path
      d="M12.2064 10.0302C11.5597 8.9793 10.6435 8.11704 9.53875 7.57812C8.75733 8.25177 7.7334 8.65595 6.62862 8.65595C5.52385 8.65595 4.49992 8.25177 3.7185 7.60507C1.58979 8.70984 0.107783 10.9194 0 13.5331C0 13.5331 0 15.7157 0 15.7427C0 16.6319 2.85624 17.3594 6.38611 17.3863C6.30528 16.9821 6.25139 16.551 6.25139 16.1199C6.25139 12.7786 8.919 10.0841 12.2064 10.0302Z"
      fill="currentColor"
    />
  </svg>
);

export const GroupIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={groupIcon} {...props} />
);
