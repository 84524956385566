import { getModuleContainer, module } from "inversify-sugar";
import NotificationRepository from "./infrastructure/NotificationRepository";
import NotificationUseCase from "./application/NotificationUseCase";
import { INotificationRepositoryToken } from "./business/INotificationRepository";

@module({
  providers: [
    {
      provide: INotificationRepositoryToken,
      useClass: NotificationRepository,
    },
    NotificationUseCase,
  ],
})
export class NotificationModule {}

export const notificationModuleContainer =
  getModuleContainer(NotificationModule);
