import { message } from "antd";
import ApiService from "core/presentation/services/ApiService";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import notificationImage from "../..//assets/images/notification.png";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATION_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATION_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATION_PROJECT_ID,
  storageBucket:
    process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATION_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATION_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATION_APP_ID,
  measurementId:
    process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATION_MEASUREMENT_ID,
};

export const pushNotification = () => {
  if (Notification?.permission !== "granted") {
    askNotificationPermission();
  }

  initializePushNotification();
};

const askNotificationPermission = () => {
  if (!("Notification" in window)) {
    message.info("This browser does not support notifications.");
    return;
  }

  Notification.requestPermission();
};

const initializePushNotification = () => {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATION_VAPID_KEY,
  })
    .then(async (currentToken) => {
      if (currentToken) {
        await ApiService.setPost("/notification/send", {
          fcmToken: currentToken,
        });
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });

  onMessage(messaging, (payload: any) => {
    const body = payload.notification.body;
    const title = payload.notification.title;
    const notification = new Notification(title, {
      body,
      icon: notificationImage,
    });

    notification.onclick = () => {
      window.open(document.URL, "_self");
    };
  });
};
