import { Menu, Button, Grid, Drawer } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { ReactElement, useEffect, useState } from "react";
import { color } from "theme/Color";
import "./Sidebar.css";
import { NotificationIcon } from "../../../assets/icons/NotificationIcon";
import { SettingsIcon } from "../../../assets/icons/SettingsIcon";
import { BarsIcon } from "../../../assets/icons/Bars";
import Footer from "../components/Footer";
import { useAppSelector } from "../store/hooks";
import { typography } from "theme/Typography";
import { CloseOutlined, BookOutlined } from "@ant-design/icons";
import { GroupIcon } from "../../../assets/icons/Group";
import { PlatformAdminIcon } from "../../../assets/icons/PlatformAdminIcon";
import { SummarizeIcon } from "../../../assets/icons/SummarizeIcon";
import { CircleIcon } from "../../../assets/icons/CircleIcon";
import { useMobile } from "../hooks/useMobile";
import { VehicleIcon } from "../../../assets/icons/VehicleIcon";
import { ShipperIcon } from "../../../assets/icons/ShipperIcon";
import { LiveTrackingIcon } from "../../../assets/icons/LiveTrackingIcon";
import { PaymentIcon } from "../../../assets/icons/PaymentIcon";
import { NotificationPlusIcon } from "../../../assets/icons/NotificationPlusIcon";
import Notifications from "notifications/presentation/pages/Notifications";
import { VehicleCategoryIcon } from "../../../assets/icons/VehicleCategoryIcon";
import { TripIcon } from "../../../assets/icons/TripIcon";
import { ContractIcon } from "../../../assets/icons/ContractIcon";
import { ChangeCircleIcon } from "../../../assets/icons/ChangeCircleIcon";
import _ from "lodash";
import { OrderIcon } from "../../../assets/icons/OrderIcon";
import { EuroCircleOutlined, UserOutlined } from "@ant-design/icons";

export interface MenuItems {
  key: string;
  href: string;
  icon: JSX.Element;
  children?: ReactElement[];
  permission: string;
  label: string;
}

interface AMenuItem extends MenuItems {
  type?: "group";
}

type KeyLabelObject = {
  [key: string]: React.ReactNode;
};

var keyLabelObject: KeyLabelObject = {};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  href?: string,
  children?: AMenuItem[],
  type?: "group"
): AMenuItem {
  return {
    key,
    icon,
    href,
    children,
    label,
    type,
  } as AMenuItem;
}

export const Sidebar: React.FC<any> = (props) => {
  const isMobile = useMobile();

  const { collapsed, setCollapsed } = props;
  const { userProfile } = useAppSelector((state) => state.user);
  const userRole = userProfile?.user?.roles[0]?.roleCode;

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedKey, setSelectedKey] = useState<string>("1");
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const createdRole = _.filter(userProfile?.user?.roles, { systemDefault: 0 });

  const isCreatedRole = createdRole.length > 0;

  useEffect(() => {
    setLoading(false);
  }, [selectedKey]);

  const items: AMenuItem[] = [
    getItem("Dashboards", "1", <BarsIcon />, "/"),
    (userProfile?.user?.permissions.includes("bid_request:list") ||
      userProfile?.user?.permissions.includes("re_bid:list_all") ||
      userProfile?.user?.permissions.includes("order:list") ||
      userProfile?.user?.permissions.includes("order:list_all")) &&
      getItem(
        userRole === "VEHICLE_OWNER" ? "Orders and Bids" : "Requests and Bids",
        "2",
        <PaymentIcon />,
        "/orders"
      ),
    ((userProfile?.user?.permissions.includes("order:list") &&
      userRole === "SHIPPER") ||
      userProfile?.user?.permissions.includes("order:list_all")) &&
      getItem("Orders", "42", <OrderIcon />, "/order"),
    // getItem("Bids", "17", <PaymentIcon />, "/bid-requests"),
    getItem("Notification", "3", <NotificationIcon />, ""),
    userRole !== "SUPER_ADMIN" &&
      getItem(
        "My Profile",
        "45",
        <UserOutlined style={{ fontSize: "20px" }} />,
        "/user-profile"
      ),
    getItem("Settings", "4", <SettingsIcon />, "/settings"),
    userProfile?.user?.permissions.includes("vehicle_owner:list_all") &&
      getItem("Vehicle Owner", "11", <VehicleIcon />, "/vehicle-owner"),
    userProfile?.user?.permissions.includes("vehicle_shift:list_all") &&
      getItem(
        "Vehicle Shift Requests",
        "28",
        <ChangeCircleIcon />,
        "/vehicle-shift-requests"
      ),

    userProfile?.user?.permissions.includes("re_bid:list_all") &&
      getItem("Canceled Bids", "46", <EuroCircleOutlined />, "/order-cancel"),

    (userProfile?.user?.permissions.includes("trip:list") ||
      userProfile?.user?.permissions.includes("trip:list_all")) &&
      getItem("Trip ", "26", <TripIcon />, "/trip"),
    userProfile?.user?.permissions.includes("shipper:list_all") &&
      getItem("Shipper", "12", <ShipperIcon />, "/shipper"),
    userProfile?.user?.permissions.includes("user:list_all") &&
      getItem("Users", "6", <PlatformAdminIcon />, "/users"),
    (userProfile?.user?.permissions.includes("driver:list") ||
      userProfile?.user?.permissions.includes("driver:list_all")) &&
      getItem("Drivers", "5", <GroupIcon />, "/driver"),
    (isCreatedRole ||
      userProfile?.user?.permissions.includes("device_location:list")) &&
      userRole !== "SHIPPER" &&
      getItem("Live Tracking", "13", <LiveTrackingIcon />, "/live-tracking"),
    userProfile?.user?.permissions.includes("role:list_all") &&
      getItem("Roles and Permissions", "14", <GroupIcon />, "/roles"),
    getItem(
      "Customized Notification",
      "20",
      <NotificationPlusIcon />,
      "/customized-notifications-shipper",
      [
        getItem(
          "Shipper",
          "21",
          <CircleIcon style={{ width: "12pt" }} />,
          "/customized-notifications-shipper"
        ),
        getItem(
          "Vehicle Owner",
          "22",
          <CircleIcon style={{ width: "12pt" }} />,
          "/customized-notifications-vehicle-owner"
        ),
        getItem(
          "Driver",
          "23",
          <CircleIcon style={{ width: "12pt" }} />,
          "/customized-notifications-driver"
        ),
      ]
    ),
    // userRole === "VEHICLE_OWNER" &&
    userProfile?.user?.permissions.includes("vehicle:list") &&
      userRole === "VEHICLE_OWNER" &&
      getItem("Vehicle ", "25", <VehicleCategoryIcon />, "/vehicle"),
    (userProfile?.user?.permissions.includes("vehicle:list_all") ||
      userProfile?.user?.permissions.includes("vehicle_category:list_all")) &&
      isCreatedRole &&
      getItem("Vehicles", "24", <VehicleCategoryIcon />, "", [
        userProfile?.user?.permissions.includes("vehicle:list_all") &&
          getItem(
            "Vehicle",
            "40",
            <CircleIcon style={{ width: "12pt" }} />,
            "/vehicle"
          ),
        userProfile?.user?.permissions.includes("vehicle_category:list_all") &&
          getItem(
            "Vehicle Category",
            "15",
            <CircleIcon style={{ width: "12pt" }} />,
            "/vehicle-category"
          ),
        userProfile?.user?.permissions.includes("vehicle_category:list_all") &&
          getItem(
            "Vehicle Type",
            "16",
            <CircleIcon style={{ width: "12pt" }} />,
            "/vehicle-type"
          ),
      ]),
    userRole === "VEHICLE_OWNER" &&
      getItem(
        "Verify Vehicle Information",
        "46",
        <ContractIcon />,
        "/tqms-vehicle-list"
      ),

    userRole === "VEHICLE_OWNER" &&
      getItem("Rejected Vehicle", "47", <ContractIcon />, "/rejected-vehicle"),
    userProfile?.user?.permissions.includes("tqms:list_all") &&
      getItem("Pending Verification", "34", <ContractIcon />, "", [
        userProfile?.user?.permissions.includes("tqms:list_all") &&
          getItem(
            "Vehicle Owner",
            "35",
            <CircleIcon style={{ width: "12pt" }} />,
            "/tqms-vehicle-owner"
          ),
        // userProfile?.user?.permissions.includes("tqms:list_all") &&
        //   getItem(
        //     "Vehicle",
        //     "36",
        //     <CircleIcon style={{ width: "12pt" }} />,
        //     "/tqms-vehicle"
        //   ),
        // userProfile?.user?.permissions.includes("tqms:list_all") &&
        //   getItem(
        //     "Driver",
        //     "37",
        //     <CircleIcon style={{ width: "12pt" }} />,
        //     "/tqms-driver"
        //   ),
        // userRole === "VEHICLE_OWNER" &&
        //   getItem(
        //     "Vehicle List",
        //     "37",
        //     <CircleIcon style={{ width: "12pt" }} />,
        //     "/tqms-vehicle-list"
        //   ),
        ,
      ]),
    userRole !== "SHIPPER" &&
      userRole !== "VEHICLE_OWNER" &&
      userProfile?.user?.permissions.includes("digital_contract:update") &&
      getItem("Digital Contract", "25", <ContractIcon />, "/digital-contract"),
    (userProfile?.user?.permissions.includes("report:list_all") ||
      userRole === "SHIPPER" ||
      userRole === "VEHICLE_OWNER") &&
      getItem("Reports", "7", <SummarizeIcon />, "", [
        userProfile?.user?.permissions.includes("report:list_all") &&
          getItem(
            "Vehicle Owner Report",
            "8",
            <CircleIcon style={{ width: "12pt" }} />,
            "/vehicle-owner-report"
          ),
        userProfile?.user?.permissions.includes("report:list_all") &&
          getItem(
            "Driver Report",
            "9",
            <CircleIcon style={{ width: "12pt" }} />,
            "/driver-report"
          ),
        // userRole?.startsWith("PLATFORM_ADMIN") &&
        userProfile?.user?.permissions.includes("report:list_all") &&
          getItem(
            "Vehicle Report",
            "10",
            <CircleIcon style={{ width: "12pt" }} />,
            "/vehicle-report"
          ),
        userRole === "VEHICLE_OWNER" &&
          getItem(
            "Expiry Report",
            "30",
            <CircleIcon style={{ width: "12pt" }} />,
            "/expiry-report"
          ),
        userRole === "VEHICLE_OWNER" &&
          getItem(
            "Vehicle Log History",
            "31",
            <CircleIcon style={{ width: "12pt" }} />,
            "/vehicle-history"
          ),
        userRole === "VEHICLE_OWNER" &&
          getItem(
            "GPS Report",
            "32",
            <CircleIcon style={{ width: "12pt" }} />,
            "/GPS-report"
          ),
        userRole === "VEHICLE_OWNER" &&
          getItem(
            "Trip Report",
            "43",
            <CircleIcon style={{ width: "12pt" }} />,
            "/trip-report"
          ),
        userRole === "VEHICLE_OWNER" &&
          getItem(
            "Driver Report",
            "44",
            <CircleIcon style={{ width: "12pt" }} />,
            "/driver-reports"
          ),
        userProfile?.user?.permissions.includes("report:list_all") &&
          getItem(
            "Vehicle Checklist",
            "17",
            <CircleIcon style={{ width: "12pt" }} />,
            "/vehicle-Checklist-report"
          ),
        // userRole === "SHIPPER" &&
        //   getItem(
        //     "Vehicle Report",
        //     "10",
        //     <CircleIcon style={{ width: "12pt" }} />,
        //     "/vehicle-report"
        //   ),
        userRole === "SHIPPER" &&
          getItem(
            "Shipment Tracking Report",
            "38",
            <CircleIcon style={{ width: "12pt" }} />,
            "/shipment-tracking-report"
          ),
        userRole === "SHIPPER" &&
          getItem(
            "Shipment Calender Report",
            "39",
            <CircleIcon style={{ width: "12pt" }} />,
            "/shipment-calender"
          ),
        userRole === "SHIPPER" &&
          getItem(
            "Shipment Dispatch Report",
            "41",
            <CircleIcon style={{ width: "12pt" }} />,
            "/shipment-dispatch"
          ),
        // userRole?.startsWith("PLATFORM_ADMIN") &&
        userProfile?.user?.permissions.includes("report:list_all") &&
          getItem(
            "Declined Vehicle Report",
            "27",
            <CircleIcon style={{ width: "12pt" }} />,
            "/declined-vehicle-report"
          ),
        // userRole?.startsWith("PLATFORM_ADMIN") &&
        userProfile?.user?.permissions.includes("report:list_all") &&
          getItem(
            "Edit Request Vehicle Report",
            "29",
            <CircleIcon style={{ width: "12pt" }} />,
            "/edit-request-vehicle-report"
          ),
        // userRole?.startsWith("PLATFORM_ADMIN") &&
        userProfile?.user?.permissions.includes("report:list_all") &&
          getItem(
            "Awating Approval Vehicle Report",
            "30",
            <CircleIcon style={{ width: "12pt" }} />,
            "/awating-approval-vehicle-report"
          ),
      ]),
  ].filter((item) => hasRolePermission(item, userRole));
  function hasRolePermission(item: AMenuItem, userRole: any): boolean {
    switch (item?.label) {
      case "Drivers":
        return (
          userProfile?.user?.permissions.includes("driver:list") ||
          userProfile?.user?.permissions.includes("driver:list_all")
        );
      case "Customized Notification":
        return userProfile?.user?.permissions.includes(
          "customize_notification:detail"
        );
      case "Trip":
        return (
          userProfile?.user?.permissions.includes("trip:list") ||
          userProfile?.user?.permissions.includes("trip:list_all")
        );
      case "Vehicle Owner":
        return userProfile?.user?.permissions.includes(
          "vehicle_owner:list_all"
        );
      case "Canceled Bids":
        return userProfile?.user?.permissions.includes("re_bid:list_all");
      // case "Platform Admins":
      //   return userProfile?.user?.permissions.includes("platform_admin:read");
      case "Reports":
        return (
          // userProfile?.user?.permissions.includes(
          //   "vehicle_owner_report:read"
          // ) ||
          // userProfile?.user?.permissions.includes("driver_report:read") ||
          // userProfile?.user?.permissions.includes("vehicle_report:read") ||
          userRole === "SHIPPER" ||
          userRole === "VEHICLE_OWNER" ||
          userProfile?.user?.permissions.includes("report:list_all")
        );
      case "Vehicle Owner Report":
        return userProfile?.user?.permissions.includes(
          "vehicle_owner_report:list"
        );
      case "Driver Report":
        return userProfile?.user?.permissions.includes("driver_report:list");
      case "Vehicle Report":
        return userProfile?.user?.permissions.includes("vehicle_report:list");
      case "Shipper":
        return userProfile?.user?.permissions.includes("shipper:list_all");
      case "Live Tracking":
        return (
          userProfile?.user?.permissions.includes("device_location:list_all") ||
          userProfile?.user?.permissions.includes("device_location:list")
        );
      case "Roles and Permissions":
        return userProfile?.user?.permissions.includes("role:list_all");
      case "Requests and Bids":
        return (
          // userProfile?.user?.permissions.includes("order:read") &&
          userRole === "SHIPPER" ||
          userRole === "VEHICLE_OWNER" ||
          userRole?.startsWith("PLATFORM_ADMIN")
        );
      // case "Bids":
      //   return (
      //     userProfile?.user?.permissions.includes("bid:read") &&
      //     userRole === "SHIPPER"
      //   );
      case "Vehicles":
        return (
          userProfile?.user?.permissions.includes("vehicle:list_all") ||
          (userProfile?.user?.permissions.includes(
            "vehicle_category:list_all"
          ) &&
            isCreatedRole)
        );

      case "Vehicle":
        return userProfile?.user?.permissions.includes("vehicle:list");
      case "Vehicle Category":
        return userProfile?.user?.permissions.includes(
          "vehicle_category:detail"
        );
      case "Vehicle Type":
        return userProfile?.user?.permissions.includes("vehicle_type:list_all");
      case "Settings":
        return userProfile?.user?.permissions.includes("profile:update");
      default:
        return true;
    }
  }

  const onClick = ({ key, item }: any) => {
    const label = keyLabelObject[item.key];
    setSelectedKey(key); // Update state
    localStorage.setItem("selectedMenuKey", key); // Persist selection
    if (key === "3") {
      setNotificationVisible(true);
    } else {
      navigate(item?.props?.href, { state: { label: label } });
    }
  };

  const findSelectedMenuItem = (menuItems: any, cb: any) => {
    const routeParts = location.pathname.split("/");
    const firstRoutePart = routeParts[1];

    menuItems.forEach((menuItem: AMenuItem) => {
      if (menuItem?.children) {
        return findSelectedMenuItem(menuItem.children, cb);
      }
      if (menuItem?.href === `/${firstRoutePart}`) {
        return cb(menuItem);
      }
    });
  };

  const findParentKeys = (
    menuItems: AMenuItem[],
    key: string,
    parentKeys: string[] = []
  ): string[] | null => {
    for (let menuItem of menuItems) {
      if (menuItem?.key === key) {
        return parentKeys;
      }
      if (menuItem?.children) {
        const foundParentKeys = findParentKeys(menuItem?.children as any, key, [
          ...parentKeys,
          menuItem?.key,
        ]);
        if (foundParentKeys) {
          return foundParentKeys;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    findSelectedMenuItem(items, (selectedItem: AMenuItem) => {
      if (selectedItem) {
        setSelectedKey(selectedItem?.key);
        localStorage.setItem("selectedMenuKey", selectedItem.key); // Persist selection
        const parentKeys = findParentKeys(items, selectedItem?.key);
        if (parentKeys) {
          setOpenKeys(parentKeys);
        }
      } else {
        setSelectedKey("1");
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    const savedKey = localStorage.getItem("selectedMenuKey");
    if (savedKey) {
      setSelectedKey(savedKey);
    }
  }, []); // Empty dependency array ensures this runs only on mount

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  if (loading) {
    return null;
  }

  const topItems: AMenuItem[] = items.filter(
    (item) => item?.key !== "3" && item?.key !== "4" && item?.key !== "45"
  );
  const bottomItems: AMenuItem[] = items.filter(
    (item) => item?.key === "3" || item?.key === "45" || item?.key === "4"
  );

  return isMobile ? (
    <>
      <Drawer
        title="Menu"
        placement="left"
        closable={false}
        onClose={() => setCollapsed(true)}
        open={!collapsed}
        bodyStyle={{ padding: 0 }}
        headerStyle={{ display: "none" }}
      >
        <>
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => setCollapsed(collapsed)}
            style={{
              position: "absolute",
              top: "20px",
              right: "10px",
              zIndex: 1,
            }}
          />
          <div
            style={{
              padding: "1.5rem 0.5rem",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <img
              width={"120px"}
              style={{ marginLeft: "15px" }}
              src={require("../../../assets/images/lodbodlogo.png")}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "90vh",
            }}
          >
            <Menu
              onClick={onClick}
              mode="inline"
              selectedKeys={[selectedKey]}
              openKeys={openKeys}
              onOpenChange={handleOpenChange}
              defaultSelectedKeys={["1"]}
              items={topItems}
              className="custom-sidebar-menu"
              style={{
                width: "max-content !important",
                paddingBottom: "30px",
                fontSize: typography.fontSizes.md,
                fontWeight: typography.fontWeights.semibold,
                background: "white",
                color: color.SIDEBAR_TEXT,
              }}
            />
            <div>
              <Menu
                onClick={onClick}
                mode="inline"
                selectedKeys={[selectedKey]}
                openKeys={openKeys}
                onOpenChange={handleOpenChange}
                defaultSelectedKeys={["1"]}
                items={bottomItems}
                className="custom-sidebar-menu"
                style={{
                  width: "max-content !important",
                  paddingBottom: "10px",
                  background: "white",
                  fontSize: typography.fontSizes.md,
                  fontWeight: typography.fontWeights.semibold,
                  color: color.SIDEBAR_TEXT,
                }}
              />

              <Footer />
            </div>
          </div>
        </>
      </Drawer>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setNotificationVisible(false)}
        open={notificationVisible}
        width="40%"
        style={{ zIndex: 1001, background: "#fff" }}
      >
        <Notifications
          notificationVisible={notificationVisible}
          setNotificationVisible={setNotificationVisible}
        />
      </Drawer>
    </>
  ) : (
    // ) : (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={380}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          zIndex: "5",
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: color.WHITE,
        }}
      >
        {!collapsed && (
          <>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => setCollapsed(collapsed)}
              style={{
                position: "absolute",
                top: "20px",
                right: "10px",
                zIndex: 1,
              }}
            />
            <div
              style={{
                padding: "1.5rem 0.5rem",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <img
                width={"120px"}
                style={{ marginLeft: "15px" }}
                src={require("../../../assets/images/lodbodlogo.png")}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "90vh",
              }}
            >
              <Menu
                onClick={onClick}
                mode="inline"
                selectedKeys={[selectedKey]}
                openKeys={openKeys}
                onOpenChange={handleOpenChange}
                defaultSelectedKeys={["1"]}
                className="custom-sidebar-menu"
                items={topItems}
                style={{
                  width: "max-content !important",
                  paddingBottom: "30px",
                  fontSize: typography.fontSizes.md,
                  fontWeight: typography.fontWeights.semibold,
                  background: "white",
                  color: color.SIDEBAR_TEXT,
                }}
              />
              <div>
                <Menu
                  onClick={onClick}
                  mode="inline"
                  selectedKeys={[selectedKey]}
                  openKeys={openKeys}
                  onOpenChange={handleOpenChange}
                  defaultSelectedKeys={["1"]}
                  items={bottomItems}
                  className="custom-sidebar-menu"
                  style={{
                    width: "max-content !important",
                    paddingBottom: "10px",
                    background: "white",
                    fontSize: typography.fontSizes.md,
                    fontWeight: typography.fontWeights.semibold,
                    color: color.SIDEBAR_TEXT,
                  }}
                />

                <Footer />
              </div>
            </div>
          </>
        )}
      </Sider>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setNotificationVisible(false)}
        open={notificationVisible}
        width="40%"
        style={{ zIndex: 1001, background: "#fff" }}
      >
        <Notifications
          notificationVisible={notificationVisible}
          setNotificationVisible={setNotificationVisible}
        />
      </Drawer>
    </>
  );
};

export default Sidebar;
