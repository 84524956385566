import { getModuleContainer, module } from "inversify-sugar";
import I18n from "./presentation/i18n";

@module({
  providers: [I18n],
})
export class CoreModule {}

export const coreModuleContainer = getModuleContainer(CoreModule);

// import { getModuleContainer, module, injectable } from "inversify-sugar";
// import { inject } from "inversify";
// import I18n from "./presentation/i18n";

// @module({
//   providers: [I18n],
// })
// export class CoreModule {
//   @inject(I18n) public i18n!: I18n;
// }

// export const coreModuleContainer = getModuleContainer(CoreModule);
