import React, { lazy } from "react";

const userRoutes = [
  {
    path: "/",
    Component: lazy(() => import("../pages/Login/index")),
  },
  {
    path: "/register",
    Component: lazy(() => import("../pages/Signup/index")),
  },
  {
    path: "/verifyEmail",
    Component: lazy(() => import("../components/VerifyEmail")),
  },
  {
    path: "/verifyNumber",
    Component: lazy(() => import("../components/VerifyNumber")),
  },
  {
    path: "/verifyUsername",
    Component: lazy(() => import("../components/ForgotPassword/VerifyUsername")),
  },
  {
    path: "/forgotPassword",
    Component: lazy(() => import("../components/ForgotPassword/ForgotPassword")),
  },
  {
    path: "/resetPassword",
    Component: lazy(() => import("../components/ForgotPassword/PasswordReset")),
  },
  {
    path: "/setPassword",
    Component: lazy(
      () => import("../components/Login/SetPassword")
    ),
  },
  {
    path: "/privacy-policy",
    Component: lazy(
      () => import("../components/PrivacyPolicy/PrivacyPolicy")
    ),
  },
  {
    path: "*",
    Component: React.lazy(
      () => import("../../../core/presentation/pages/NotFound")
    ),
  },
];

export default userRoutes;
