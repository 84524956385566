import {
  NotificationMessage,
  NotificationTagsParams,
  NotificationType,
} from "notifications/presentation/store/types";

export const INotificationRepositoryToken = Symbol();

export interface INotificationRepository {
  getNotificationDay: () => Promise<any>;
  updateNotificationDay: (value: any) => Promise<any>;
  getNotificationTags: (params: NotificationTagsParams) => Promise<any>;
  createNotificationMessage: (value: NotificationMessage) => Promise<any>;
  getNotification: () => Promise<any>;
  seenNotification: (id: number) => Promise<NotificationType>;
  seenAllNotification: () => Promise<NotificationType>;
}
