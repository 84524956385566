import { getModuleContainer, module } from "inversify-sugar";
import { IUserRepositoryToken } from "./business/IUserRepository";
import UserRepository from "./infrastructure/UserRepository";
import UserUseCase from "./application/UserUseCase";

@module({
  providers: [
    {
      provide: IUserRepositoryToken,
      useClass: UserRepository,
    },
    UserUseCase,
  ],
})
export class UserModule {}

export const userModuleContainer = getModuleContainer(UserModule);
