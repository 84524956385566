import SuccessModal from "core/presentation/components/SuccessModal";
import { useAppSelector } from "core/presentation/store/hooks";

const Welcome = ({ onClose, showWelcomeModal }: any) => {
  const { user } = useAppSelector((state) => state.user);

  const userName = user?.firstName ? user?.firstName : "";

  return (
    <>
      <SuccessModal
        show={showWelcomeModal}
        successMessage={`Welcome to LODBOD, ${userName}! We're excited to have you onboard. Let’s get started!`}
        onClose={onClose}
        continueButtonText="Continue"
      />
    </>
  );
};

export default Welcome;
