import React, { ReactNode } from "react";
import { ConfigProvider, theme } from "antd";
import { color } from "./Color";
import { typography } from "./Typography";

interface ThemeConfigProviderProps {
  children: ReactNode | ReactNode[];
}

const ThemeConfigProvider = ({ children }: ThemeConfigProviderProps) => {
  return (
    <ConfigProvider
      theme={{
        // algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: color.PRIMARY,
          fontFamily: "'Inter', sans-serif",
          colorBgBase: color.LIGHT,
          colorFill: color.PRIMARY,
          colorSuccess: color.SUCCESS,
          colorError: color.DANGER,
          colorPrimaryHover: color.PRIMARY,
          fontSizeHeading1: typography.fontSizes.lg,
          fontSizeHeading2: typography.fontSizes.md,
          fontSizeHeading3: typography.fontSizes.sm,
          fontSize: typography.fontSizes.xs,
        },
        components: {
          Radio: {
            colorPrimary: color.PRIMARY,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeConfigProvider;
