export const IOrderRepositoryToken = Symbol();

export interface IOrderRepository {
  // Driver
  // createDriver: (values: any) => Promise<any>;
  // updateDriver: (id: any, values: any) => Promise<any>;
  // getAllDriver: () => Promise<any>;
  // getADriver: (id: any) => Promise<any>;
  // deleteDriver: (id: any) => Promise<any>;
  getUnits: () => Promise<any>;
  getVehicleTypes: () => Promise<any>;
  orderCancelShipper: (id: any, values: any) => Promise<any>;
  getAllPickupLocations: () => Promise<any>;
  getAllDeliveryLocations: () => Promise<any>;
  getAllOrders: (
    status?: string,
    page?: number,
    input?: string,
    filter?:string
  ) => Promise<any>;
  getBidRequests: (id: any) => Promise<any>;
  placeOrder: (values: any, action?:string) => Promise<any>;
  getOrderDetail: (id: any,deletePrev? :boolean) => Promise<any>;
  updateOrder: (id: any, values: any) => Promise<any>;
  getShipperBids: () => Promise<any>;
  shipperAcceptBids: (bidId: number) => Promise<any>;
  getCoords: (values: any) => Promise<any>;
  getDigitalContractWithBidRequest: (id: any) => Promise<any>;
  acknowledgeDigitalContract: (values: any) => Promise<any>;
  getBidHistory: () => Promise<any>;
  putSubmitOrder: (values:any, orderId: number) => Promise<any>;
  getPaidBidCancel: (page: number, input: string, filter:string ) => Promise<any>;
  rebid: (values: any) => Promise<any>;
  updateDraftOrder: (id: number, value: any) => Promise<any>;

  cancelRequestShipper: (orderNumber: any, value: any) => Promise<any>;
  getNewRequest: (page?: number) => Promise<any>;
  getAcceptedRequest: (page?: number) => Promise<any>;

  getRequestAndOrder: (
    status?: string,
    page?: number,
    input?: string,
    filter? :string
  ) => Promise<any>;
  getBidList: (vehicleSpecId: number) => Promise<any>;
  cancelOrder: ( orderId: number) => Promise<any>;
  extendExpiry: (id: number) => Promise<any>;

}
