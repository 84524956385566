import fetch, { blobService } from "./FetchInterceptor";

interface ApiResponse<T> {
  data: T;
}

const apiService = {
  getPost: async function (
    url: string,
    params: string
  ): Promise<ApiResponse<{}>> {
    try {
      const response = await fetch({
        url: url,
        method: "get",
        params,
      });

      const data = await response;

      return { data };
    } catch (error: any) {
      console.error("Error in getPost:", error);
      return Promise.reject(error.response.data);
    }
  },

  setPost: async function (url: string, data: any): Promise<ApiResponse<{}>> {
    try {
      const response = await fetch({
        url: url,
        method: "post",
        data,
      });

      const responseData = await response;

      return { data: responseData };
    } catch (error: any) {
      console.error("Error in setPost:", error);
      return Promise.reject(error.response.data);
    }
  },

  updatePost: async function (
    url: string,
    data: any
  ): Promise<ApiResponse<{}> | any> {
    try {
      const response = await fetch({
        url: url,
        method: "put",
        data,
      });

      const responseData = await response;

      return { data: responseData };
    } catch (error: any) {
      return Promise.reject(error.response.data);
    }
  },

  deletePost: async function (
    url: string,
    data: any
  ): Promise<ApiResponse<{}>> {
    try {
      const response = await fetch({
        url: url,
        method: "delete",
        data,
      });

      const responseData = await response;

      return { data: responseData };
    } catch (error: any) {
      console.error("Error in deletePost:", error);
      return Promise.reject(error.response.data);
    }
  },

  getBlobPost: async function (url: string, data: any): Promise<any> {
    try {
      const response = await blobService.get(url, data);
      return response;
    } catch (error: any) {
      console.error("Error in setPost:", error);
      return Promise.reject(error.response.data);
    }
  },
};

export default apiService;
