import { VehicleCategory, VehicleInfo } from "fleet/business/Fleet";
import * as IFleetRepository from "../business/IFleetRepository";
import { injectable, provided } from "inversify-sugar";

@injectable()
export default class FleetUseCase {
  @provided(IFleetRepository.IFleetRepositoryToken)
  private fleetRepository!: IFleetRepository.IFleetRepository;

  public createVehicleCategory(values: any) {
    return this.fleetRepository.createVehicleCategory(values);
  }

  public getAllVechiles(page: number, input: string, filter:string) {
    return this.fleetRepository.getAllVechiles(page, input,filter);
  }

  public getAVehicle(id: number) {
    return this.fleetRepository.getAVehicle(id);
  }

  public deleteVehicle(id: number) {
    return this.fleetRepository.deleteVehicle(id);
  }

  public updateVehicle(id: number, values: VehicleCategory) {
    return this.fleetRepository.updateVehicle(id, values);
  }

  public changeVehicleRequestStatus(id: number, values: any) {
    return this.fleetRepository.changeVehicleRequestStatus(id, values);
  }

  public checkVehicleExistance(values: any) {
    return this.fleetRepository.checkVehicleExistance(values);
  }
  public getAllChangeRequests(page: number, input: string,filter:string) {
    return this.fleetRepository.getAllChangeRequests(page, input,filter);
  }

  public changeVehicleOwn(id: number, values: any) {
    return this.fleetRepository.changeVehicleOwn(id, values);
  }

  public changeVehicleOthers(id: number, values: any) {
    return this.fleetRepository.changeVehicleOthers(id, values);
  }

  public getAllVehicleCategory(page: number, input: string, filter  :string) {
    return this.fleetRepository.getAllVehicleCategory(page, input,filter);
  }

  public deleteVehicleCategory(id: number) {
    return this.fleetRepository.deleteVehicleCategory(id);
  }

  public getVehicleCategory(id: number) {
    return this.fleetRepository.getVehicleCategory(id);
  }

  public updateVehicleCategory(id: number, values: VehicleCategory) {
    return this.fleetRepository.updateVehicleCategory(id, values);
  }

  public getUnits() {
    return this.fleetRepository.getUnits();
  }

  public createUnit(values: any) {
    return this.fleetRepository.createUnit(values);
  }

  public createVehicleTypes(values: any) {
    return this.fleetRepository.createVehicleTypes(values);
  }

  public getAllVehicleTypes(page: number, input: string,filter: string) {
    return this.fleetRepository.getAllVehicleTypes(page, input,filter);
  }

  public deleteVehicleType(id: number) {
    return this.fleetRepository.deleteVehicleType(id);
  }

  public getVehicleType(id: number) {
    return this.fleetRepository.getVehicleType(id);
  }

  public getAllVehicleOwnerList = () => {
    return this.fleetRepository.getAllVehicleOwnerList();
  };

  public getTripCoordinatesById = (id: number) => {
    return this.fleetRepository.getTripCoordinatesById(id);
  };

  public getAllVehiclePlateColors = () => {
    return this.fleetRepository.getAllVehiclePlateColors();
  };

  public getVehiclePolicyTypes = () => {
    return this.fleetRepository.getVehiclePolicyTypes();
  };

  public createVehicle = (values: any) => {
    return this.fleetRepository.createVehicle(values);
  };

  public transferOwnership = (values: any) => {
    return this.fleetRepository.transferOwnership(values);
  };

  public createTrip = (values: any) => {
    return this.fleetRepository.createTrip(values);
  };
  public getAllTrip(page: number, input: string,filter:string) {
    return this.fleetRepository.getAllTrip(page, input,filter);
  }

  public deleteTrip(id: number) {
    return this.fleetRepository.deleteTrip(id);
  }

  public getATrip(id: number) {
    return this.fleetRepository.getATrip(id);
  }

  public updateTrip(id: number, values: any) {
    return this.fleetRepository.updateTrip(id, values);
  }

  public updateTripStatus(id: number, values: any) {
    return this.fleetRepository.updateTripStatus(id, values);
  }
  public getAvailableVehicleForBidding(id: number) {
    return this.fleetRepository.getAvailableVehicleForBidding(id);
  }

  public getNewBids(page: number, input?: string,filter? :string) {
    return this.fleetRepository.getNewBids(page, input,filter);
  }

  //new bid request
  public newBidRequest(values: any) {
    return this.fleetRepository.newBidRequest(values);
  }

  public getRejectedBids(page: number, input: string,filter?:string) {
    return this.fleetRepository.getRejectedBids(page, input,filter);
  }
  public getAcceptedBids(page: number, input?: string,filter?:string) {
    return this.fleetRepository.getAcceptedBids(page, input,filter);
  }
  public acknowledgeDigitalContract(id: number) {
    return this.fleetRepository.acknowledgeDigitalContract(id);
  }

  public acknowledgeContract(values: any) {
    return this.fleetRepository.acknowledgeContract(values);
  }

  //cancel bid request
  public cancelBidRequest(values: VehicleInfo) {
    return this.fleetRepository.cancelBidRequest(values);
  }

  //payment
  public addpayment(values: any) {
    return this.fleetRepository.addpayment(values);
  }

  //cancel order
  public cancelOrder(values: VehicleInfo) {
    return this.fleetRepository.cancelOrder(values);
  }

  public getAllVehicles() {
    return this.fleetRepository.getAllVehicles();
  }

  public getVehicleCheckList(page: number, input: string) {
    return this.fleetRepository.getVehicleCheckList(page, input);
  }

  public getVehicleLogHistory(page: number, input: string) {
    return this.fleetRepository.getVehicleLogHistory(page, input);
  }
  public getDeclinedVehicleReport(page: number, input: string) {
    return this.fleetRepository.getDeclinedVehicleReport(page, input);
  }
  public getRejectedVehicleReport(page: number, input: string) {
    return this.fleetRepository.getRejectedVehicleReport(page, input);
  }
  public getEditRequestVehicleReport(page: number, input: string) {
    return this.fleetRepository.getEditRequestVehicleReport(page, input);
  }
  public getAwaitingApprovalVehicleReport(page: number, input: string) {
    return this.fleetRepository.getAwaitingApprovalVehicleReport(page, input);
  }
  public getAwaitingApprovalDriverReport(
    type: string,
    page: number,
    input: string
  ) {
    return this.fleetRepository.getAwaitingApprovalDriverReport(
      type,
      page,
      input
    );
  }

  public getStatusVehicleReport(values: any) {
    return this.fleetRepository.getStatusVehicleReport(values);
  }

  public getProformaInvoice(id: number) {
    return this.fleetRepository.getProformaInvoice(id);
  }

  public getStatusDriverReport(values: any) {
    return this.fleetRepository.getStatusDriverReport(values);
  }
  //insurance company
  public getInsuranceCompany() {
    return this.fleetRepository.getInsuranceCompany();
  }

  public checkUniqueVehicleNumber(vehicleNumber: string) {
    return this.fleetRepository.checkUniqueVehicleNumber(vehicleNumber);
  }

  public getUnAssignedDriver() {
    return this.fleetRepository.getUnassignedDriver();
  }
  public assignDriver(values: any) {
    return this.fleetRepository.assignDriver(values);
  }

  public updateVehicleStatus(id: number, data: any) {
    return this.fleetRepository.updateVehicleStatus(id, data);
  }

  public getOrderBidDetails(id: number) {
    return this.fleetRepository.getOrderBidDetails(id);
  }

  //Getting the onboarding data
  public getOnboardingData(
    status: string,
    type: string,
    page: number,
    input: string
  ) {
    return this.fleetRepository.getOnboardingData(status, type, page, input);
  }

  public getVehicleOnBoardingLists(page: number, input: string,filter:string) {
    return this.fleetRepository.getVehicleOnBoardingLists(page, input,filter);
  }

  public setVehicleEditOnBoarding(id: number, value: any) {
    return this.fleetRepository.setVehicleEditOnBoarding(id, value);
  }

  //for getting the details of vehicle by id
  public getVehicleDetailsById(value: { vehicleId: number }) {
    return this.fleetRepository.getVehicleDetailsById(value);
  }

  //sthaniya
  public getSthaniya() {
    return this.fleetRepository.getSthaniya();
  }

  public getTQMSVehicleDetails(id: number) {
    return this.fleetRepository.getTQMSVehicleDetails(id);
  }
}
