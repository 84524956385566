import { Typography } from "antd";
import { typography } from "theme/Typography";

interface ATitleProps {
  content: string;
  textColor?: string;
  fontSize?: number;
  fontWeight?: number | string;
  lineHeight?: string | number;
  marginTop?: string;
  [x: string]: any;
}

function ATitle({
  content,
  textColor,
  fontSize,
  fontWeight,
  lineHeight,
  marginTop,
  marginBottom,
  ...others
}: ATitleProps) {
  const { Title } = Typography;

  const style: React.CSSProperties = {
    color: textColor || "#101828",
    fontSize: fontSize || typography.fontSizes.fourxl,
    fontWeight: fontWeight || typography.fontWeights.semibold,
    lineHeight: lineHeight || "44px",
    marginTop: marginTop,
    marginBottom: marginBottom || 0,
    ...others,
  };

  return (
    <Title style={style} {...others}>
      {content}
    </Title>
  );
}

export default ATitle;
