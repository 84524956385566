import { JwtPayload, jwtDecode } from "jwt-decode";
import { AUTH_TOKEN, ID_TOKEN, REFRESH_TOKEN } from "../constants/Auth";

export const AuthStorage = {
  setToken: (token: string) => {
    try {
      localStorage.setItem(AUTH_TOKEN, token);
    } catch (error) {
      console.error("Error storing token:", error);
    }
  },

  setSession: async (authResponse: any) => {
    try {
      const {
        accessToken = "",
        idToken = "",
        refreshToken = "",
      } = authResponse || {};

      await localStorage.setItem(AUTH_TOKEN, accessToken);
      await localStorage.setItem(ID_TOKEN, idToken);
      await localStorage.setItem(REFRESH_TOKEN, refreshToken);
    } catch (error) {
      console.log("Error storing session:", error);
    }
  },
  getToken: () => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN);
      return token;
    } catch (error) {
      console.error("Error getting token:", error);
      return null;
    }
  },

  getFreshToken: async () => {
    const refreshToken = await localStorage.getItem(REFRESH_TOKEN);
    return refreshToken;
    //get refresh toekn from api
  },
  removeToken: () => {
    try {
      localStorage.removeItem(AUTH_TOKEN);
    } catch (error) {
      console.error("Error removing token:", error);
    }
  },
  removeSession: async () => {
    await localStorage.removeItem(AUTH_TOKEN);
    await localStorage.removeItem(ID_TOKEN);
    await localStorage.removeItem(REFRESH_TOKEN);
  },

  isTokenExpired: (accessToken: string) => {
    const decodedToken = jwtDecode<JwtPayload>(accessToken);
    return decodedToken?.exp && decodedToken.exp < Date.now();
  }
};
