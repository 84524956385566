import React from "react";
import { Modal } from "antd";
import AButton from "./Button";
import { FailedIcon } from "../../../assets/icons/Failed";
import { typography } from "theme/Typography";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  failedMessage: string;
  additionalText: string;
  continueButtonText: string;
  attemptText?: string;
}

const FailedModal: React.FC<ModalProps> = ({
  show,
  onClose,
  failedMessage,
  additionalText,
  continueButtonText,
  attemptText,
}) => {
  return (
    <Modal
      open={show}
      onCancel={onClose}
      width={400}
      closeIcon={null}
      centered
      footer={[
        <AButton
          key="continueButton"
          onClick={onClose}
          fullwidth
          type="primary"
        >
          {continueButtonText}
        </AButton>,
      ]}
    >
      <div className="modal-body">
        <FailedIcon />
        <p
          style={{
            fontSize: typography.fontSizes.lg,
            fontWeight: typography.fontWeights.semibold,
            lineHeight: "28px",
          }}
        >
          {failedMessage}
        </p>
        <p
          style={{
            fontSize: typography.fontSizes.sm,
            fontWeight: typography.fontWeights.normal,
            lineHeight: "20px",
          }}
        >
          {additionalText}
        </p>
        {attemptText && (
          <p
            style={{
              fontSize: typography.fontSizes.sm,
              fontWeight: typography.fontWeights.normal,
              lineHeight: "20px",
            }}
          >
            {attemptText}
          </p>
        )}
      </div>
    </Modal>
  );
};

export default FailedModal;
