import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const vehicleIcon = () => (
  <svg
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 14C4.16667 14 3.45833 13.7083 2.875 13.125C2.29167 12.5417 2 11.8333 2 11H0V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16L22 6V11H20C20 11.8333 19.7083 12.5417 19.125 13.125C18.5417 13.7083 17.8333 14 17 14C16.1667 14 15.4583 13.7083 14.875 13.125C14.2917 12.5417 14 11.8333 14 11H8C8 11.8333 7.70833 12.5417 7.125 13.125C6.54167 13.7083 5.83333 14 5 14ZM14 5H18L15 2H14V5ZM8 5H12V2H8V5ZM2 5H6V2H2V5ZM5 12.25C5.35 12.25 5.64583 12.1292 5.8875 11.8875C6.12917 11.6458 6.25 11.35 6.25 11C6.25 10.65 6.12917 10.3542 5.8875 10.1125C5.64583 9.87083 5.35 9.75 5 9.75C4.65 9.75 4.35417 9.87083 4.1125 10.1125C3.87083 10.3542 3.75 10.65 3.75 11C3.75 11.35 3.87083 11.6458 4.1125 11.8875C4.35417 12.1292 4.65 12.25 5 12.25ZM17 12.25C17.35 12.25 17.6458 12.1292 17.8875 11.8875C18.1292 11.6458 18.25 11.35 18.25 11C18.25 10.65 18.1292 10.3542 17.8875 10.1125C17.6458 9.87083 17.35 9.75 17 9.75C16.65 9.75 16.3542 9.87083 16.1125 10.1125C15.8708 10.3542 15.75 10.65 15.75 11C15.75 11.35 15.8708 11.6458 16.1125 11.8875C16.3542 12.1292 16.65 12.25 17 12.25ZM2 9H2.8C3.08333 8.7 3.40833 8.45833 3.775 8.275C4.14167 8.09167 4.55 8 5 8C5.45 8 5.85833 8.09167 6.225 8.275C6.59167 8.45833 6.91667 8.7 7.2 9H14.8C15.0833 8.7 15.4083 8.45833 15.775 8.275C16.1417 8.09167 16.55 8 17 8C17.45 8 17.8583 8.09167 18.225 8.275C18.5917 8.45833 18.9167 8.7 19.2 9H20V7H2V9Z"
      fill="currentColor"
    />
  </svg>
);

export const VehicleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={vehicleIcon} {...props} />
);
