import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import RootNavigator from "./types/RootNavigator";
import Loader from "../components/Loader";

export default function Navigation() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <RootNavigator />
      </Suspense>
    </BrowserRouter>
  );
  return <RootNavigator />;
}
