import { getModuleContainer, module } from "inversify-sugar";
import { IOrderRepositoryToken } from "./business/IOrderRepository";
import OrderRepository from "./instructure/OrderRepository";
import OrderUseCase from "./application/OrderUseCase";

@module({
  providers: [
    {
      provide: IOrderRepositoryToken,
      useClass: OrderRepository,
    },
    OrderUseCase,
  ],
})
export class OrderModule {}

export const orderModuleContainer = getModuleContainer(OrderModule);
